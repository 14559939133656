
.home {
  position: relative;
  top: 50px;
  @supports (-webkit-appearance: none) {
    height: calc(100dvh - 90px);
  }
  height: calc(100vh - 90px);
  background: white;
  overflow-y: scroll;
  overflow-x: hidden;
  .home-wrapper-mobile {
    border-bottom: solid hsl(0, 0%, 92%) 1px;
    position: relative;
    text-align: center;
  }
}

@media only screen and (min-width: 600px) and (max-width: 1399px) {
  .home {
    width: 100%;
    .home-wrapper-pc{
      display: none;
    }
    .home-wrapper-mobile {
      padding-top: 60px;
      padding-bottom: 60px;

      .home-logo {
        width: 300px;
      }
    }

    .lists-wrapper {
      padding-bottom: 50px;
    }
  }
}

@media only screen and (min-width: 1400px) {
  .home {
    background: rgba(72, 104, 137, 0.05);
    .scrollbar-children{
      overflow: visible !important;
    }
    width: 100%;
    .home-wrapper-mobile {
      display: none;
    }
    .home-wrapper-pc{
      padding: 20px 0;
      position: relative;
      width: 1050px;
      left:  calc(50% - 500px);

      .home-bottom-wrapper{
        display: flex;
        gap: 50px;
        justify-content: space-between;
        align-items: flex-start;
        .home-sidebar {
          position: -webkit-sticky;
          position: sticky;
          top: 20px;
          margin-top: 65px;
          width: 300px;
          background: white;
          border-radius: 10px;
          padding: 10px 20px;
          height: auto;
          .home-sidebar-item {
            border-bottom: #E7E7E7 solid 1px;

            &:last-of-type {
              border: none;
            }

            padding-bottom: 10px;

            .home-sidebar-title {
              margin: 10px;
              font-size: 20px;
              color: #AAAAAA;
            }

            .home-sidebar-learning {
              .learning-card-item {
                border-radius: 10px;
                padding: 10px 20px;
                background: #EDF2FC;
                margin-bottom: 15px;

                .ant-progress-text {
                  color: #AAAAAA !important;
                }

                .learning-card-name {
                  color: #486889;
                  font-size: 16px;
                  margin-bottom: 4px;
                }
              }
            }

            .home-sidebar-recommend {
              .recommend-card-item {
                position: relative;
                margin-bottom: 20px;
                &:last-of-type{
                  margin-bottom: 0;
                }
                padding: 20px;
                background: #F6F7F9;
                border-radius: 10px;
                .recommend-item-name{
                  color: #486889;
                  font-size: 14px;
                  font-weight: bold;
                }
                .recommend-item-price{
                  color: #AAAAAA;
                  font-size: 12px;
                  font-weight: bold;
                }
                .recommend-item-des{
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  color:rgba(166, 166, 166, 0.9412);
                  font-size: 12px;
                }
                .recommend-buy-button{
                  position: absolute;
                  right: 10px;
                  top: 22px;
                  color: #486889;
                  font-size: 12px;
                }
              }
            }
          }
        }
        .home-zhuanlan-list{
          flex: 1;
          .list-card-item{
            .list-name{
              color: #7B7B7B;
              font-weight: bold;
              font-size: 22px;
              padding: 15px 0;
            }
            .list-content{
              display: flex;
              flex-wrap:wrap;
              gap: 20px;
              .zhuanlan-card-item{
                position: relative;
                width: 220px;
                background: white;
                border-radius: 10px;
                padding: 10px 20px 20px 20px;
                display: flex;
                flex-flow: column;
                align-items: center;
                .zhuanlan-card-image{
                  width: 120px;
                  margin-top: -10px;
                }
                .zhuanlan-card-name{
                  margin-top:  -10px;
                  text-align: center;
                  color:rgba(166, 166, 166, 0.9412);
                  font-size: 14px;
                  font-weight: bold;
                }
                .zhuanlan-card-des{
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  color:rgba(166, 166, 166, 0.9412);
                  font-size: 12px;
                }
                .zhuanlan-card-notice{
                  position: absolute;
                  font-size: 12px;
                  color: #486889;
                  text-align: center;
                  bottom: 5px;
                }
              }
            }
          }
        }
      }
      .home-carousel{
        background: #FFFFFF;
        border-radius: 10px;
        padding: 20px;
        .navigation-menu{
          margin-left: -10px;
          justify-content: flex-start !important;
          .navigation-menu-item{
            font-size: 16px !important;
          }
          .navigation-menu-item-activated{
            font-size: 16px !important;
          }
        }
        .home-carousel-cards{
          padding: 6px 10px;
          display: flex;
          justify-content: flex-start;
          gap: 40px;
          .carousel-card-item{
            background: #F6F7F9;
            width: 300px;
            border-radius: 10px;
            padding: 20px 20px 20px 20px;
            .carousel-item-image{
              width: 100px;
              margin:  -20px 0 -10px 0;
            }
            .carousel-item-name{
              text-align: center;
              color: rgba(166, 166, 166, 0.9412);
              font-size: 16px;
            }
            .carousel-item-des{
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
              color:rgba(166, 166, 166, 0.9412);
              font-size: 12px;
            }
            .carousel-original-price{
              color: #AAAAAA;
              font-size: 12px;
              font-weight: 500;
              text-decoration: line-through;
            }
            .carousel-price{
              color: #AAAAAA;
              font-weight: 500;
              font-size: 12px;
            }
            .carousel-buy-button{
              margin-top: 6px;
              color: #486889;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 599px) {
  .home {
    width: 100vw;
    .home-wrapper-pc{
      display: none;
    }
    .home-wrapper-mobile {
      padding-top: 50px;
      padding-bottom: 50px;
      .home-logo {
        width: 200px;
      }
      .slogan-wrapper{
        top: 20px;
        .line{
        visibility: hidden;
      }
      }
    }
    .lists-wrapper {
      padding-bottom: 50px;
    }
  }
}
