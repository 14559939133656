.recruit-settings{
    padding: 20px;
   @media only screen and (max-width: 599px){
    padding: 5px;
  }
  .recruit-back{
    cursor: pointer;
    white-space: pre;
    font-weight: bold;
    color: dimgrey;
    font-size: 16px;
  }
  .recruit-settings-list{
    @media only screen and (min-width: 1200px){
      padding: 0 20px;
      margin-top: 10px;
    }
    @media only screen and (min-width: 600px) and (max-width: 1199px) {
      padding: 0 10px;
    }
    @media only screen and (max-width: 599px){
      padding: 0 5px;
    }
    .recruit-settings-item{
      @media only screen and (min-width: 1200px){
        font-size: 20px;
        padding: 20px 0;
      }
     @media only screen and (min-width: 600px) and (max-width: 1199px) {
       font-size: 18px;
       padding: 20px 0;
     }
      @media only screen and (max-width: 599px){
        font-size: 14px;
        padding: 12px 0;
      }
      border-bottom: #e8e8e8 solid 1.5px;
      .recruit-settings-item-switch{
        float: right;
        @media only screen and (min-width: 1200px){
          padding-right: 20px;
        }
        @media only screen and (min-width: 600px) and (max-width: 1199px) {
          padding-right: 10px;
        }
      }
    }
  }
}