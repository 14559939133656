._comment-input {
  ._comment-input-textarea {
    margin: 10px 0 20px 0;
  }
}

._comments-list {
  margin-top: 48px;
  ._comments-list-title {
    font-size: 16px;
    font-weight: bold;
    color: #606060;
  }
}

._comment-item {
  border-bottom: solid #e8e8e8 1px;
  padding: 20px 20px 16px 20px;
  ._comment-item {
    padding-right: 0;
  }
  display: flex;
  gap: 20px;
  ._comment-item-main {
    flex-grow: 1;
  }
}
._comment-item:last-of-type {
  border-bottom: none;
}

._comment-content {
  color: #606060;
  padding: 8px;
  font-size: 14px;
}

._comment-username {
  color: #404040;
  font-size: 16px;
}

._comment-time {
  color: darkgrey;
}

._comment-action-bar {
  color: darkgray;
  margin-top: 12px;
  ._comment-action-item {
    cursor: pointer;
  }
  ._comment-action-item:hover {
    color: #5995da;
  }
}

._comment-reply-bar {
  margin-top: 12px;
  display: flex;
  gap: 12px;
}
