.checkout-wrapper{
  position: relative;
  padding-bottom: 100px;
  @media only screen and (min-width: 620px) {
    top: 50px;
  }
  .section{
    border-top:solid #5995DA 1px;
    padding: 20px 10px;
    &:last-of-type{
     border-bottom: solid #5995DA 1px;
    }
  }
  .checkout{
    @media only screen and (min-width: 1400px){
      width: 1000px;
      left: calc(50% - 500px);
    }
    @media only screen and (max-width : 1399px) and (min-width: 820px){
      width: 800px;
      left: calc(50% - 400px);
    }
    @media only screen and (max-width: 819px){
      width: 90%;
      left: 5%;
      top:50px;
    }
    position: relative;
    margin-top: 20px;
    .renew-select{
      padding: 0 10px;
      margin-top: 20px;
      .renew-select-title{
        color: #5995DA;
        font-size: 20px;
        margin-bottom: 20px;
      }
      .renew-select-item-wrapper{
        display: flex;
        .renew-select-item{
          cursor: pointer;
          text-align: center;
          width: 120px;
          margin-right: 20px;
          font-size: 14px;
          padding: 4px 12px;
          background: white;
          color: #5995DA;
          border: #5995DA solid 1px;
          transition: 0.3s;
          &:hover{
            background: #5995DA;
            color: white;
          }
        }
      }
    }
    .checkout-title{
      letter-spacing: 10px;
      font-size: 30px;
      margin-top: 20px;
      margin-bottom: 10px;
      color: #5995DA;
      text-align: center;
    }
    .checkout-line{
      margin-bottom: 15px;
      margin-top: 15px;
      height: 1px;
      background: #5995DA;
    }
    .testpaper-info{
      padding-left: 10px;
      padding-right: 10px;
      .info-title{
        color: #5995DA;
        font-size: 20px;
        margin-bottom: 20px;
      }
      .testpaper-title{
        font-size: 16px;
        padding-top: 10px;
        margin-bottom: 10px;
      }
      .testpaper-price{
        font-size: 16px;
        position: relative;
        left: 20px;
        margin-bottom: 10px;
      }
    }
    .testpaper-collection-info{
      margin-bottom: 10px;
      padding-left: 10px;
      padding-right: 10px;
      .info-title{
        margin-bottom: 20px;
        color: #5995DA;
        font-size: 20px;
      }
      .testpaper-title{
        font-size: 16px;
        padding-top: 10px;
        margin-bottom: 10px;
      }
      .testpaper-price{
        font-size: 16px;
        position: relative;
        left: 20px;
        margin-bottom: 10px;
      }
      .testpaper-collection-intro{
        margin-top: 10px;
        font-size: 14px;
      }
    }
    .zhuanlan-info{
      padding-left: 10px;
      padding-right: 10px;
      .info-title{
        margin-bottom: 20px;
        color: #5995DA;
        font-size: 20px;
      }
      .testpaper-title{
        font-size: 16px;
        padding-top: 10px;
        margin-bottom: 10px;
      }
      .testpaper-price{
        font-size: 16px;
        position: relative;
        left: 20px;
        margin-bottom: 10px;
      }
      .testpaper-collection-intro{
        margin-top: 10px;
        font-size: 14px;
      }
    }
    .category-info{
      padding-left: 10px;
      padding-right: 10px;
      .info-title{
        margin-bottom: 20px;
        color: #5995DA;
        font-size: 20px;
      }
      .testpaper-title{
        font-size: 16px;
        padding-top: 10px;
        margin-bottom: 10px;
      }
      .testpaper-price{
        font-size: 16px;
        position: relative;
        left: 20px;
        margin-bottom: 10px;
      }
      .testpaper-collection-intro{
        margin-top: 10px;
        margin-bottom: 10px;
        font-size: 14px;
      }
    }
    .attention{
      margin-top: 20px;
      padding-left: 10px;
      padding-right: 10px;
      font-size: 14px;
      color: #5995DA;
    }
    .payment-wrapper{
      padding-right: 10px;
      padding-left: 10px;
      .pay-icon{
        font-size: 20px;
        margin-left: 5px;
        margin-right: 5px;
        margin-bottom: -2px;
      }
      .payment-title{
        color: #5995DA;
        font-size: 20px;
        margin-bottom: 20px;
      }
      .payment-info{
        font-size: 14px;
        margin-top: 20px;
        margin-bottom: 50px;
      }
      .wechat-button{
        font-size: 14px;
        padding: 6px 10px;
        color: #5995DA;
        background: white;
        border: #5995DA solid  1px;
        white-space: pre;
      }
      .alipay-button{
        font-size: 14px;
        white-space: pre;
        margin-left: 20px;
        padding: 6px 10px;
        color: #5995DA;
        background: white;
        border: #5995DA solid  1px;
      }
      .buy-button{
        @media only screen and (min-width: 1400px){
          left:  880px;
        }
        @media only screen and (max-width : 1399px) and (min-width: 820px){
          left: 680px;
        }
        @media only screen and (max-width: 819px){
         left: calc(100% - 80px);
        }
        transition: 0.3s;
        position: relative;
        font-size: 14px;
        padding: 6px 10px;
        color: white;
        background:  #5995DA;
        border: #5995DA solid  1px;
        white-space: pre;
        &.button-primary{
          &:hover{
            cursor: pointer;
            background: white;
            color: #5995DA;
          }
        }
      }
    }
  }
}
