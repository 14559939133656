.coupon-list-wrapper{
  max-height: 250px;
  overflow: auto;
}
.coupon-modal-item{
  display: flex;
  padding: 12px 20px;
  box-shadow: 0 2px 6px lightgrey;
  border-radius: 10px;
  margin-bottom: 10px;
  align-items: center;
  .coupon-modal-discount{
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    width: 70px;
    font-size: 20px;
    color: #5995DA;
    margin-right: 10px;
    font-weight: bold;
  }
  .coupon-modal-content{
    padding-left: 20px;
    border-left: dashed #9d9d9d 1px;
    .coupon-modal-name{
      font-size: 14px;
    }
    .coupon-modal-description{
      font-size: 12px;
      color: #9d9d9d;
    }
  }
}
.coupon-modal-message{
  text-align: center;
  font-size: 12px;
  color: lightgrey;
}
