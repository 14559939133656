.wrapper {
  width:100%;
  min-height:100%;
  position: relative;
  bottom: 0;
  top: 0;
  height: auto;
}

.hint-no-item{
  text-align: center;
  font-size: 16px;
  opacity: 0.4;
  padding: 12px;
}

.hint-no-data-inline{
  font-size: 16px;
  opacity: 0.6;
  font-style: italic;
}

