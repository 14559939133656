.discuss-item{
  margin-top: 30px;
  border-bottom: #D8D8D8 solid 1px;
  &:last-of-type{
    border: none;
  }
  .discuss-top-bar{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .discuss-user-avatar{
      margin-right: 12px;
    }
    .discuss-title{
      color: #3D3D3D;
      font-weight: 500;
      font-size: 20px;
      line-height: 23px;
    }
    .discuss-tag{
      color: #5189FF;
      font-size: 16px;
    }
    .discuss-select-tag{
      margin-left: 20px;
      background: #5189FF;
      border-radius: 10px;
      padding: 2px 12px;
      color: white;
      font-size: 16px;
    }
  }
  .discuss-content{
    font-size: 16px;
    color:#3D3D3D;
    &-preview{
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 10px;
    }
    .discuss-detail-link{
      color: #5189FF;
      font-weight: 500;
    }
  }
  .discuss-preview-img{
    .preview-image-wrapper{
      padding: 10px 0 20px 0;
      .preview-img-item{
        width: 150px;
        padding:0 20px 0 0;
      }
    }
  }
  .discuss-bottom-bar{
    padding: 0 10px 8px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .discuss-time{
      color:#7B7B7B;
      @media only screen and (max-width: 599px) {
        display: none;
      }
      font-size: 14px;
    }
    .discuss-action{
      .discuss-action-text{
        @media only screen and (max-width: 599px) {
          display: none;
        }
      cursor: pointer;
      margin-right: 30px;
      color:#7B7B7B;
      font-size: 14px;
      }
      @media only screen and (max-width: 599px) {
        font-size: 12px;
        margin-right: 10px;
      }
      transition: 0.3s;
      &:last-of-type{
        margin-right: 0;
      }
      &:hover{
        color: #5189FF;
      }
      &-liked{
        cursor: pointer;
        margin-right: 30px;
        color: #5189FF;
        font-size: 14px;
        @media only screen and (max-width: 599px) {
          font-size: 12px;
          margin-right: 10px;
        }
        .discuss-action-text {
          @media only screen and (max-width: 599px) {
            display: none;
          }
        }
      }
    }
  }
}
