.ant-popover-inner-content{
  a{
    color: hsl(0, 0%, 80%);
  }
  a:hover{
    color: hsl(0, 0%, 30%);
  }
  .right-angle{
    color: hsl(0, 0%, 80%);
  }
}
.path-tree-judge {
  border-left: solid hsl(0, 0%, 92%) 1px;
  z-index: 4;
  line-height: 25px;
  position: fixed;
  max-width: calc(100vw - 170px);
  top: 0px;
  left: 120px;
  visibility: hidden;
  a {
    text-decoration: none;
  }
  a:hover {
    color: hsl(0, 0%, 30%);
    text-decoration: underline;
  }
  .right-angle {
    white-space: pre;
    color: hsl(0, 0%, 80%);
    font-family: Verdana;
    position: relative;
    top: 12px;
  }
  .path-name {
    color: hsl(0, 0%, 80%) ;
    font-family: Verdana;
    position: relative;
    top: 12px;
  }
}
@media only screen and (max-width: 599px) {
  .path-tree-judge {
    display: none;
  }
}
@media only screen and (min-width: 600px) {
  .path-tree-judge {
    padding-left: 20px;
    .right-angle {
      font-size: 16px;
    }
    .path-name {
      font-size: 16px;
    }
  }
}

.path-tree {
  line-height: 25px;
  min-height: 50px;
  border-left: solid hsl(0, 0%, 92%) 1px;
  z-index: 11;
  position: fixed;
  max-width: calc(100vw - 170px);
  top: 0px;
  left: 120px;
  a {
    text-decoration: none;
  }
  a:hover {
    color: hsl(0, 0%, 30%);
    text-decoration: underline;
  }
  .right-angle {
    white-space: pre;
    color: hsl(0, 0%, 80%);
    font-family: Verdana;
    position: relative;
    top: 12px;
  }
  .path-name {
    color: hsl(0, 0%, 80%);
    font-family: Verdana;
    position: relative;
    top: 12px;
  }
}
@media only screen and (max-width: 599px) {
  .path-tree {
    display: none;
  }
}
@media only screen and (min-width: 600px) {
  .path-tree {
    padding-left: 10px;
    .right-angle {
      font-size: 16px;
    }
    .path-name {
      font-size: 16px;
    }
  }
}