.recruitment-question-set {
  position: relative;
  top: 50px;
  height: calc(100vh - 90px);
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: #fcfcfc;

  .recruitment-question-set-wrapper {
    margin: 0 auto;
    display: grid;
    grid-template-columns: minmax(100%, 780px);
    grid-template-rows: auto auto auto;
    #recommend-list {
      grid-row: 1;
    }
    #hottest-questions {
      grid-row: 2;
    }
    #all-questions {
      grid-row: 3;
    }
    @media only screen and (max-width: 599px) {
      max-width: 780px;
      padding: 8px;
      gap: 16px;
    }
    @media only screen and (min-width: 599px) {
      max-width: 780px;
      padding: 16px;
      gap: 16px;
    }
    @media only screen and (min-width: 899px) {
      max-width: 1200px;
      padding: 24px;
      gap: 24px;
    }
  }

  .section-header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 8px;

    .section-title {
      font-size: 20px;
      font-weight: bold;
      color: #505050;
    }
  }

  #recommend-list .section-container {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    @media only screen and (max-width: 899px) {
      .card-item {
        width: 100%;
      }
    }
    @media only screen and (min-width: 899px) {
      .card-item {
        width: calc((100% - 12px) / 2);
      }
    }
  }

  #everyday-question .section-container {
    .ant-picker-calendar-header,
    .ant-picker-panel {
      background-color: #fcfcfc;
    }
  }
}
