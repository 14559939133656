.title{
  z-index: 10;
  position: fixed;
  top: 0;
  width: 100%;
  height: 50px;
  border-bottom: solid hsl(0, 0%, 92%) 2px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .left{
    img{
      padding-left: 20px;
      height: 30px;
    }
  }
  .right{
    display: flex;
    margin-right: 30px;
    .title-button-login{
      height: 50px;
      display: flex;
      font-size: 14px;
      margin: 0 10px;
      color: dimgray;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      cursor: pointer;
    }
    .title-button{
      box-sizing: border-box;
      display: flex;
      height: 50px;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      cursor: pointer;
      font-size: 14px;
      margin: 0 10px;
      color: dimgray;
      transition: 0.3s;
        &:hover{
          color:#5995DA;
        }
      &.active{

        border-bottom: 5px #9BC4FF solid;
      }
    }
    .title-button-img{
      cursor: pointer;
      font-size: 18px;
      margin: 0 2px;
      color: dimgray;
    }
      .unread-message-cnt{
        transform: translateX(15px) translateY(-60%);
        position: absolute;
        background: #5995DA;
        padding: 0 4px;
        min-width: 15px;
        border-radius: 10px;
        color: #fff;
        font-size: 12px;
        line-height: 15px;
    }
  }
  // .title-link-info {
  //   position: absolute;
  //   top: 15px;
  //   font-size: 16px;
  //   color: hsl(0, 0%, 80%);
  //   @media only screen and (max-width: 599px){
  //     right: 70px;
  //   }
  //   @media only screen and (min-width: 600px){
  //     right: 80px;
  //   }
  // }
  // .title-link-login{
  //   @media only screen and (max-width: 599px){
  //     right: 20px;
  //   }
  //   @media only screen and (min-width: 600px){
  //     right: 30px;
  //   }
  //   position: absolute;
  //   top: 15px;
  //   font-size: 16px;
  //   color: hsl(0, 0%, 80%);
  // }
}

.menu-item{
  margin: 12px 0 0 0;
  font-size: 16px;
  a{
    color: dimgray;
  }
  a:hover{
    color: dodgerblue;
  }
  svg{
    margin: 0 5px 0 0 ;
  }
  .menu-unread-message-cnt{
    margin-left: 10px;
    position: relative;
    background: #5995DA;
    padding: 0 4px;
    min-width: 15px;
    border-radius: 10px;
    color: #fff;
    font-size: 12px;
    line-height: 15px;
  }
}
.menu-title{
  color: dimgray;
}
