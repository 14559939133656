.teacher-wrapper{
  position: relative;
  top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
  .teacher-wrapper-inner{
    @media only screen and (min-width: 1025px){
      width: 600px;
    }
    @media only screen and (min-width: 600px) and (max-width: 1024px) {
      width: 500px;
    }
    @media only screen and (max-width: 599px){
      width: 90%;
      position: relative;
    }

    .back-button{
      svg{
        color: #a8a8a8;
        padding-top: 20px;
        height: 32px;
        width: 32px;
      }
    }
    .teacher{
      margin-top: 10px;
      display: grid;
      @media only screen and (max-width: 599px) {
        grid-template-rows: 40px 30px 30px auto;
        grid-template-columns: 110px auto 130px;
      }
      @media only screen and (min-width: 600px) {
        grid-template-rows: 48px 35px 48px 130px;
        grid-template-columns: 130px auto 130px;
      }
      align-items: center;
      border-bottom: solid 2px gainsboro;
      .teacher-avatar{
        @media only screen and (max-width: 599px) {
          height: 100px;
          width: 80px;
        }
        @media only screen and (min-width: 600px){
          height: 120px;
          width: 100px;
        }
        border-radius: 10px;
        background-size: cover;
        background-image: url("https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT4aQho8HdWbxfZsSzp59JOWqG7tVEF0B_rKw&usqp=CAU");
        grid-row: 1 / 4;
        margin: 0 15px 0 15px;

      }
      .teacher-name{
        @media only screen and (max-width: 599px) {
          font-size: 20px;
        }
        @media only screen and (min-width: 600px) {
          font-size: 26px;
        }

      }
      .teacher-consult-info{
        display: flex;
        align-items: center;
        @media only screen and (max-width: 599px) {
          font-size: 12px;
        }
        @media only screen and (min-width: 600px) {
          font-size: 16px;
        }
        color: #8a8a8a;
        .info-text{
          padding-left: 2px;
          @media only screen and (max-width: 599px) {
            width: 80px;
          }
          @media only screen and (min-width: 600px){
            width: 100px;
          }
        }
      }
      .teacher-position{
        color: gray;
      }
      .teacher-introduction{
        @media only screen and (max-width: 599px) {
          position: relative;
          width: 90vw;
        }
        grid-column: 1 / 4;
        padding: 10px 20px 10px 20px;
      }
      .ask-button{
        @media only screen and (max-width: 599px) {
          position: relative;
          left: -100px;
          top: -10px;
        }
        grid-column: 3 / 4;
        grid-row: 1 / 4;
        text-align: center;
        text-decoration: none;
        span{
          @media only screen and (max-width: 599px) {
            padding: 6px 10px 6px 10px;
          }
          @media only screen and (min-width: 600px) {
            padding: 8px 12px 8px 12px;
          }
          border-radius: 10px;
          color: white;
          background-color: rgb(30, 110, 205);
        }
        span:hover{
          cursor: pointer;
        }
      }
    }
    .public-questions{
      margin-bottom: 50px;
      .label{
        margin: 10px 0 0 0;
        font-size: 24px;
        color: #5c5c5c;
      }
      .question{
        margin-top: 10px;
        border: solid 2px gainsboro;
        border-radius: 18px;
        padding: 10px;
        .content{
          color: #4a4a4a;
          padding: 10px;
        }
        .question-consult-info{
          padding: 0 10px 5px 10px;
          color: #8a8a8a;
          .info-text{
            @media only screen and (max-width: 599px) {
              font-size: 12px;
              margin-right: 14px;
            }
            @media only screen and (min-width: 600px) {
              font-size: 14px;
              margin-right: 50px;
            }
            display: inline-block;

          }
          .audit-button{
            float: right;
            left: 20px;
            display: inline-block;
            text-align: end;
            span:hover{
              cursor: pointer;
            }
            span{
              background-color: rgb(194, 203, 227);
              color: rgb(69, 96, 168);
              border-radius: 10px;
              padding: 2px 6px 2px 6px;
            }
          }
        }
      }
    }
  }
}