.discuss-draft-item-wrapper{
  padding: 20px;
  border-bottom: #D8D8D8 solid 1px;
  .discuss-draft-top-wrapper{
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    align-items: center;
    .discuss-draft-title{
      color: #3D3D3D;
      font-weight: 500;
      font-size: 20px;
      line-height: 23px;
      .discuss-draft-tag{
        margin-left: 4px;
        color: #5189FF;
        font-size: 16px;
      }
    }
    .discuss-draft-action{
      display: flex;
      margin-left: 10px;
      .discuss-draft-edit{
        cursor: pointer;
        color:white;
        font-size: 14px;
        border-radius: 6px;
        background: #5189FF;
        padding: 0 12px;
        transition: 0.3s all;
        &:hover{
          opacity: 0.9;
        }
      }
      .discuss-draft-delete{
        cursor: pointer;
        color:#5189FF;
        font-size: 14px;
        border-radius: 6px;
        border: #5189FF solid 1px;
        background: white;
        padding: 0 12px;
        transition: 0.3s all;
        &:hover{
          opacity: 0.9;
        }
      }
    }
  }
  .discuss-draft-content{
    padding: 0 10px;
    font-size: 14px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 10px;
  }
}
