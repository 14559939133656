.possess-item {
  @media only screen and (max-width: 619px) {
    margin-left: 10px;
    margin-right: 10px;
    padding: 10px 10px 20px;
    font-size: 14px;
  }
  @media only screen and (min-width: 620px) {
    margin-left: 20px;
    margin-right: 20px;
    padding: 20px 20px 30px;
    font-size: 16px;
  }
  position: relative;
  border-bottom: solid lightgray 1px;

  &:last-of-type {
    border-bottom: none;
  }

  a:hover {
    color: #5995DA;
  }

  .possess-item-name {
    text-decoration: none;
    top: 14.5px;
    position: absolute;
    color: black;
  }

  .possess-item-price {
    top: 14.5px;
    left: 150px;
    position: absolute;
  }

  .possess-item-intro {
    position: relative;
    margin-top: 30px;
    font-size: 14px;
  }

  .expiration-time {
    @media only screen and (max-width: 619px) {
      position: relative;
      margin-top: 30px;
    }
    @media only screen and (min-width: 620px) {
      position: absolute;
      right: 50px;
      top: 14.5px;
    }

  }
}