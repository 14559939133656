.my-questions-wrapper{
  padding: 20px;
  .my-questions-title{
    font-size: 35px;
    padding: 20px;
  }
  span:hover{
    cursor: pointer;
  }
  .my-questions-select-box{
    border-bottom: gainsboro 2px solid;
    border-bottom-left-radius: 10px;
    padding: 10px 0 10px 0;
    margin-left: 20px;
    .my-questions-select-label{
      padding: 10px;
      font-size: 20px;
      border: solid gainsboro;
      border-width: 2px;
    }
    .first{
      border-right: 0px;
      border-radius: 10px 0 0 10px;
    }
    .second{
      border-radius: 0 10px 0 0;
    }
  }
}