.ant-input-group-addon{
  border: none;
  background: white;
}
.logindrawer-sms-button{
  cursor: pointer;
  position: relative;
  text-align: center;
  padding: 8px 16px;
  background: #5995DA;
  width: 100%;
  border-radius: 5px;
  color: white;
  margin-top: 20px;
}
.logindrawer-sms-button-loading{
  cursor: not-allowed;
  position: relative;
  text-align: center;
  padding: 8px 16px;
  background: darkgrey;
  width: 100%;
  border-radius: 5px;
  color: white;
  margin-top: 20px;
}
.logindrawer-login-button{
  cursor: pointer;
  position: relative;
  text-align: center;
  padding: 8px 16px;
  background: #5995DA;
  width: 100%;
  border-radius: 5px;
  color: white;
  margin-top: 20px;
}
.logindrawer-sms-wrapper{
  position: relative;
  height: 32px;
  .logindrawer-input-sms{
    display: inline-block;
  }
  .logindrawer-disabled-button{
    position: relative;
    width: 100px;
    top: -28px;
    left: calc(100% - 100px);
    display: inline-block;
    cursor: not-allowed;
  }
  .logindrawer-button{
    color:#5995DA;
    position: relative;
    cursor: pointer;
    width: 100px;
    top: -28px;
    left: calc(100% - 85px);
    display: inline-block;
  }
}