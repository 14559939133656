.discuss-notice{
  display: flex;
  border-bottom: #D8D8D8 solid 1px;
  padding: 10px 0;
  justify-content: space-between;
  .discuss-notice-title{
    margin-left: 20px;
    color: #3D3D3D;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
  }
  .discuss-notice-right-icon{
    font-size: 16px;
    color: #5189FF;
    margin-left: 10px;
  }
  .discuss-notice-tags{
    display: flex;
    align-items: center;
    .discuss-notice-tag{
      background: #ed706c;
      border-radius: 10px;
      padding: 2px 12px;
      color: white;
      font-size: 16px;
    }
    .discuss-select-tag{
      margin-left: 10px;
      background: #5189FF;
      border-radius: 10px;
      padding: 2px 12px;
      color: white;
      font-size: 16px;
    }
  }
}
