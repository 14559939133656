.gift-wrapper{
  position: relative;
  top:50px;
  .gift{
    top:50px;
    position: relative;
    width: 600px;
    left: calc(50% - 300px);
    .gift-button-unclaimed{
      cursor: pointer;
      left: 50px;
      margin-top: 20px;
      width: 500px;
      font-size: 16px;
      color: white;
      text-align: center;
      background: #66B8EE;
      padding: 10px;
      border-radius: 50px;
      position: relative;
    }
    .gift-button-claimed{
      left: 50px;
      margin-top: 20px;
      width: 500px;
      font-size: 16px;
      color: white;
      text-align: center;
      background: lightgrey;
      padding: 10px;
      border-radius: 50px;
      position: relative;
    }
    .reading{
      margin-top: 10px;
      display: flex;
      justify-content: center;
      color: gray;
      position: relative;
    }
    .gift-box{
      position: relative;
      width: 100%;
      border-radius: 20px;
      box-shadow:0 0 10px lightgrey;
      padding: 20px;
      .gift-noti{
        padding-top: 10px;
        font-size: 12px;
        color: darkgrey;
        border-top:solid #ACC8DC 1px;
      }
      .gift-zhuanlan{
        display: flex;
        position: relative;
        font-size: 14px;
        grid-template-columns: 200px 390px;
        grid-gap: 10px;
        .gift-zhuanlan-img{
          width: 200px;
        }
        .gift-zhuanlan-info{
          position: relative;
          .gift-zhuanlan-name{
            font-size: 14px;
            color: gray;
          }
          .gift-chapter-name{
            font-size: 18px;
          }
          .gift-presenter{
            letter-spacing: 1px;
            margin-top: 20px;
            margin-bottom: 20px;
            font-size: 20px;
          }
        }

      }
    }
  }
}