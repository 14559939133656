.drawer-wrapper{
  z-index: 102;
  display: flex;
  left: 0;
  position: fixed;
  justify-content: center;
  align-items: center;
  .drawer-layer{
    background: #000;
    opacity: 0.2;
    position:fixed;
    top:0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 1001;
  }
  .drawer-card{
    @media  only  screen and (max-width: 400px){
      left: 0;
    }
    @media  only  screen and (min-width: 401px){
      left: calc(50% - 200px);
    }
    animation: drawer1 0.4s ease-in-out;
    @keyframes drawer1 {
      0%{
        opacity: 0;
        transform:translateY(60%);
      }
      100%{
        opacity: 1;
        transform: translateY(0%);
      }
    }
    bottom: 0;
    position: fixed;
    background: white;
    z-index: 10002;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    width: 100%;
    max-width: 400px;
    box-shadow:0 0 10px lightgrey;
    padding-bottom: 10px;
    .drawer-title{
      padding: 10px;
      color: #5995DA;
      font-size: 20px;
      text-align: center;
    }
    .drawer-body{
      padding: 0 20px;
    }
    .drawer-footer{
      text-align: center;
      margin-top: 20px;
      font-size: 12px;
      color: lightgrey;
    }
  }
}