.discuss-detail{
  position: relative;
  top: 50px;
  height: calc(100vh - 90px);
  overflow-y: scroll;
  overflow-x: hidden;
  .discuss-detail-wrapper{
    @media only screen and (max-width: 599px) {
      padding: 10px;
    }
    @media only screen and (min-width: 600px) and (max-width: 1199px) {
      padding: 20px 60px;
    }
    @media only screen and (min-width: 1200px) {
      padding: 40px 200px;
    }
    .discuss-item{
      margin-top: 30px;
      border-bottom: #D8D8D8 solid 1px;
      &:last-of-type{
        border: none;
      }
      .discuss-top-bar{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .discuss-user-avatar{
          margin-right: 12px;
        }
        .discuss-title{
          color: #3D3D3D;
          font-weight: 500;
          font-size: 30px;
          line-height: 23px;
        }
        .discuss-tag{
          color: #5189FF;
          font-size: 16px;
        }
      }
      .discuss-content{
        font-size: 16px;
        color:#3D3D3D;
        padding: 20px 10px;
      }
      .discuss-bottom-bar{
        padding: 0 10px 8px 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .discuss-time{
          color:#7B7B7B;
          font-size: 14px;
        }
        .discuss-action{
          margin-right: 30px;
          color:#7B7B7B;
          font-size: 14px;
          &:last-of-type{
            margin-right: 0;
          }
        }
      }
    }

  }
}
