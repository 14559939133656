.my-courses-wrapper{
  padding: 20px;
  .my-courses-title{
    @media only screen and (min-width: 1025px){
      font-size: 30px;
      padding: 20px;
    }
    @media only screen and (max-width: 1024px) and (min-width: 600px){
      font-size: 25px;
      padding: 15px;
    }
    @media only screen and (max-width: 599px){
      font-size: 18px;
      padding: 10px;
    }

    border-bottom: solid 2px gainsboro;
    margin-bottom: 20px;
  }
  .my-courses-content{
    .course{
      align-items: center;
      box-shadow: 0 3px 20px 0 rgba(46, 61, 72, 0.05);
      margin-bottom: 10px;
      padding: 10px;
      display: flex;
      justify-content: space-between;
      .course-image{
        @media only screen and (max-width: 1024px) and (min-width: 600px){
          width: 120px;
          height: 120px;
        }
        @media only screen and (max-width: 599px){
          width: 100px;
          height: 100px;
        }
        width: 150px;
        height: 150px;
      }
      .course-name{
        @media only screen and (max-width: 1024px) and (min-width: 600px){
          font-size: 16px;
        }
        @media only screen and (max-width: 599px){
          font-size: 14px;
        }
        font-size: 20px;
        &:hover{
          text-decoration: underline #404040;
        }
      }
      .expire-time-notification{
        font-size: 12px;
        color: #5995DA;
      }
      .renew-button{
        min-width: 50px;
        @media only screen and (max-width: 1024px) and (min-width: 600px){
          font-size: 12px;
          padding: 4px 10px;
        }
        @media only screen and (max-width: 599px){
          font-size: 12px;
          padding: 4px 8px;
        }
        margin-left: 10px;
        font-size: 14px;
        margin-right: 10px;
        text-align: center;
        background: #5995DA;
        cursor: pointer;
        color: white;
        padding: 6px 20px;
        border-radius: 5px;
        transition: 0.3s;
        border: #5995DA solid 1px;
        &:hover{
          background: white;
          color: #5995DA;
        }
      }
    }
  }
}
