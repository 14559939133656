.general-wrapper{
  padding: 20px;
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-columns: 49% 49%;
  margin-bottom: 100px;
  @media only screen and (min-width: 600px){
    grid-template-rows: 290px 200px 100px;
  }
  @media only screen and (max-width: 599px){
    grid-template-rows: 265px 200px 100px;
  }
  gap: 2% 2%;
  .grid-item{
    // border: solid 1px gainsboro;
    border-radius: 10px;
    box-shadow: 0 2px 10px 0 rgba(46, 61, 72, 0.05);
  }
  .info{
    grid-column: 1 / 3;
    @media only screen and (min-width: 600px){
      padding: 20px;
    }
    @media only screen and (max-width: 599px){
      padding: 10px;
    }
    position: relative;
    .info-wrapper{
      display: flex;
      align-items: center;
      .info-avatar{
        border-radius: 50%;
        border: solid 2px rgb(73, 106, 198);
        padding: 4px;
        margin-right: 16px;
      }
      .info-basic-name{
        color: rgb(71, 71, 71);
        font-size: 20px;
        @media only screen and (max-width: 599px){
          font-size: 18px;
        }
      }
      .info-basic-birthday{
        color: rgb(174, 174, 174);
      }
    }
    .info-more-wrapper{
      padding: 10px 0;
      font-size: 16px;
      @media only screen and (max-width: 599px){
        font-size: 14px;
      }
      .one-info{
        margin: 10px 0;
        .ant-typography{
          margin-bottom: 0;
        }
        .recommend{
          @media only screen and (max-width: 649px){
            display: flex;
            margin: 10px 0 -10px 0;
          }
          @media only screen and (min-width: 650px){
            display: flex;
            float: right;
          }
        }
        .info-more-label{
          color: rgb(174, 174, 174);
        }
        .info-more-content{
          color: rgb(71, 71, 71);
          margin-left: 10px;
        }
      }
    }
    .resume-complete{
      @media only screen and (max-width: 599px){
        font-size: 12px;
      }
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: solid 1.5px rgb(107, 137, 218);
      border-radius: 10px;
      background-color: rgb(230, 236, 248);
      .resume-complete-content{
        color: rgb(107, 137, 218);
        font-weight: 600;
        @media only screen and (max-width: 599px){
          padding: 4px 8px;
        }
        padding: 6px 8px;
      }
      .resume-complete-button{
        display: block;
        background-color: rgb(107, 137, 218);
        color: white;
        font-weight: 600;
        @media only screen and (max-width: 599px){
          border-radius: 10px;
          padding: 2px 4px;
        }
        border-radius: 20px;
        padding: 2px 8px;
        margin-right: 5px;
      }
      .resume-complete-button:hover{
        cursor: pointer;
      }
    }
  }
  .courses{
    grid-row: 2 / 4;
    .one-course{
      border-bottom: solid 1px gainsboro;
      padding: 12px 4px 8px 4px;
      .course-title{
        font-weight: 600;
        @media only screen and (max-width: 599px){
          font-size: 16px;
        }
        font-size: 20px;
        color: rgb(89, 89, 89);
        padding: 8px 0;    //when progress hiden
      }
      .course-progress{
        position: relative;
        margin: 6px 8px;
        color: rgb(107, 107, 107);
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
  .questions{

  }
  .account{
    .account-balance{
      color: rgb(89, 89, 89);
      padding: 8px 4px;
      font-size: 16px;
      font-weight: 600;
      .money{
        color: rgb(107, 137, 218);
        .cent{
          font-size: 12px;
        }
      }
    }
  }
  .general-title{
    padding: 10px 0 5px 16px;
    @media only screen and (max-width: 599px){
      font-size: 16px;
    }
    font-size: 20px;
    color: rgb(107, 107, 107);
    font-weight: 600;
    border-bottom: solid 1.5px gainsboro;
    box-shadow: rgb(250, 250, 250) 0px 0px 10px 0px;
  }
  .general-content-wrapper{
    @media only screen and (max-width: 599px){
      padding: 0 8px;
    }
    padding: 0 16px;
  }
}
