.resume-wrapper{
  width: 100%;
  height: 100%;
  // position: relative;

  padding: 20px;
  .resume-item-avatar{
    position: absolute;
    top: 16px;
    right: 64px;
    height: 70px;
    width: 50px;
  }

  .resume-item-avatar img{
    height: 100%;
    width: 100%;
  }


  .resume-complete-button{
    background-color: rgb(28, 63, 160);
    color: white;
    font-size: 18px;
    font-weight: 600;
    border-radius: 12px;
    padding: 6px 8px;
  }
  .resume-complete-button:hover{
    cursor: pointer;
  }
  .resume-complete-info{
    margin-top: 12px;
    font-size: 16px;
    color: rgb(19, 61, 177);
  }

}