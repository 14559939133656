.checkout-portal {

  .payment-error {
    svg {
      margin-bottom: 10px;
      width: 100px;
      height: 100px;

      * {
        fill: darkred;
      }
    }
  }
  .payment-successful {
    svg {
      margin-bottom: 10px;
      width: 100px;
      height: 100px;

      * {
        fill: green;
      }
    }
  }
}
