.testpaper-collection{
  position: relative;
  top: 52px;
  overflow-x: hidden;
  .testpaper-collection-wrapper{
    position: relative;
    border-bottom: solid hsl(0, 0%, 92%) 1px;
    .testpaper-collection-img-wrapper{
      text-align: center;
      .testpaper-collection-img{
        position: relative;
      }
    }
    .testpaper-collection-name-wrapper{
      text-align: center;
      .testpaper-collection-name{
        color: hsl(0,0%,35%);
        position: relative;
        font-family: Verdana;
        font-weight: bold;
      }
    }
    .testpaper-collection-intro-wrapper{
      position: relative;
      padding-top: 20px;
      .testpaper-collection-intro{
        line-height: 25px;
        font-family: Verdana;
        position: relative;
        color: dimgrey;
      }
    }

    .purchase-wrapper{
      @media only screen and (min-width: 600px) {
        margin-top: 50px;
      }
      @media only screen and (max-width: 599px) {
        margin-top: 20px;
      }
      text-align: center;
      .price{
        @media only screen and (min-width: 1400px){
          left: -100px;
          font-size: 22px;
        }
        @media only screen and (min-width: 600px) and (max-width: 1399px){
          left: -110px;
          font-size: 20px;
        }
        @media only screen and (max-width: 599px){
          font-size: 14px;
          left: -60px;
        }
        position: relative;
        font-family: Verdana;
        font-weight: bold;

        color: hsl(0,0%,35%);
      }
      .purchase-frequency{
        @media only screen and (min-width: 1400px){
          left: -100px;
        }
        @media only screen and (min-width: 600px) and (max-width: 1399px){
          left: -110px;
        }
        @media only screen and (max-width: 599px){
          font-size: 12px;
          left: -60px;
        }
        color: hsl(0,0%,35%);
        position: relative;
      }
      .purchase-button:hover{
        cursor: pointer;
        color: white;
        background: #5995DA;
      }
      .purchase-button{
        @media only screen and (min-width: 600px) and (max-width: 1399px) {
          left: calc(50% - 210px);
          width: 150px;
        }
        @media only  screen  and (min-width: 1400px){
          left: calc(50% - 220px);
          width: 150px;
        }
        @media only screen and (max-width: 599px){
          left: 65px;
          width: 120px;
          letter-spacing: 2px;
          top: -35px;
          font-size: 14px;
          padding: 4px 8px;
        }
        top: -45px;
        text-align: center;
        letter-spacing: 5px;
        padding: 6px 12px 6px 12px;
        position: relative;
        font-size: 16px;
        background: white;
        border: solid #5995DA 1px;
        color: #5995DA;
      }
    }
  }
  .testpaper-list-wrapper{
    .testpaper-item{
      margin-bottom: 10px;
      padding: 10px;
      background: #fafafa;
      color: black;
      .lock-icon{
        position: absolute;
        right: 20px;
        margin-top: 5px;
        color: lightgrey;
        width: 20px;
      }
      a{
        text-decoration: none;
      }
      a:hover{
        color: #5995DA;
        text-underline: #5995DA;
      }
      .testpaper-item-title{
        color: black;
        font-size: 20px;
      }
    }
    margin-top: 50px;
    position: relative;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1399px){
  .testpaper-collection{
    width: 100%;
    padding-bottom: 50px;
    .testpaper-collection-wrapper{
      padding-bottom: 30px;
      padding-top: 50px;
      .testpaper-collection-img-wrapper{
        .testpaper-collection-img{
          width: 300px;
        }
      }
      .testpaper-collection-name-wrapper{
        padding-top: 20px;
        .testpaper-collection-name{
          font-size: 40px;
        }
      }
      .testpaper-collection-intro-wrapper{
        max-width: 550px;
        left: calc(50vw - 275px);
        .testpaper-collection-intro{
          font-size: 14px;
        }
      }
    }
    .testpaper-list-wrapper{
      width: 550px;
      left: calc(50% - 275px);
    }
  }
}
@media only screen and (min-width: 1400px) {
  .testpaper-collection{
    width: 100%;
    padding-bottom: 100px;
    .testpaper-collection-wrapper{
      padding-bottom: 40px;
      padding-top: 70px;
      .testpaper-collection-img-wrapper{
        .testpaper-collection-img{
          width: 300px;
        }
      }
      .testpaper-collection-name-wrapper{
        padding-top: 20px;
        .testpaper-collection-name{
          font-size: 40px;
        }
      }
      .testpaper-collection-intro-wrapper{
        max-width: 600px;
        left: calc(50vw - 300px);
        .testpaper-collection-intro{
          font-size: 14px;
        }
      }
    }
    .testpaper-list-wrapper{
      width: 600px;
      left: calc(50% - 300px);
    }
  }
}
@media only screen and (max-width: 599px){
  .testpaper-collection{
    width: 100vw;
    padding-bottom: 50px;
    .testpaper-collection-wrapper{
      padding-bottom: 10px;
      padding-top: 30px;
      .testpaper-collection-img-wrapper{
        .testpaper-collection-img{
          width: 180px;
        }
      }
      .testpaper-collection-name-wrapper{
        padding-top: 10px;
        text-align: center;
        .testpaper-collection-name{
          font-size: 25px;
        }
      }
      .testpaper-collection-intro-wrapper{
        width: 100vw;
        .testpaper-collection-intro{
          padding-right: 20px;
          padding-left: 20px;
          line-height: 25px;
          font-size: 14px;
        }
      }
    }
    .testpaper-list-wrapper{
      width: 90%;
      left: 5%;
    }
  }
}
