.comments{
  padding: 0 5px 0 5px;
  .comments-preview{
    text-align: center;
    color: #5995DA;
    font-size: 14px;
    opacity: 0.8;
  }
  .comments-title{
    padding-left: 20px;
    font-size: 16px;
    position: relative;
    margin-bottom: 20px;
    color: #606060;
    font-weight: bold;
  }
  .comments-input{
    padding: 10px 20px 20px 20px;
    margin-bottom: 30px;
    .comments-input-avatar{
    }
    .comments-input-user{
      color: #404040;
      margin-left: 10px;
      font-size: 16px;
    }
    .comments-input-textarea{
      padding: 0 20px;
      margin-bottom: 20px;
    }
    .comments-submit-button:hover{
      background: #4389D9;
    }
    .comments-submit-button{
      cursor: pointer;
      color: white;
      background: #5995DA;
      padding: 4px 18px;
      border-radius: 5px;
    }
  }
  .comment-item:last-of-type{
    border-bottom: none;
  }
  .comment-item{
      @media only screen and (min-width: 600px){
      padding: 20px 20px 10px 20px;
    }
    @media only screen  and (max-width: 599px){
      padding: 10px 10px 10px 10px;
    }
    border-bottom: solid #e8e8e8 1px;
    display: flex;
    .comment-user-avatar{
      position: relative;
      flex-shrink: 0;
      @media only screen  and (max-width: 599px){
        margin-right: 10px;
      }
      @media only screen and (min-width: 600px){
        margin-right: 20px;
      }
    }
    .comment-body{
      position: relative;
      flex: 1 1 auto;
      .comment-user-name{
        align-items: center;
        position: relative;
        @media only screen and (min-width: 600px){
          font-size: 16px;
        }
        @media only screen  and (max-width: 599px){
          font-size: 12px;
        }
        color: #404040;
      }
      .comment-author{
        margin-left: 2px;
        font-size: 12px;
        color: #5995DA;
        background: #f5faff;
        padding:2px 4px;
        border-radius: 10px;
      }
      .comment-date{
        @media only screen and (max-width: 599px){
          font-size: 12px;
        }
        @media only screen and (min-width: 600px){
          font-size: 14px;
        }
        margin-left: 10px;
        color: darkgrey;
      }
      .comment-sticky-topic{
        @media only screen and (max-width: 599px){
          font-size: 12px;
          padding: 4px 8px;
          right: 0;
        }
        @media only screen and (min-width: 600px){
          font-size: 14px;
          padding: 4px 12px;
          right: 10px;
        }
        border-radius: 10px;
        color: #5995DA;
        background: #f5faff;
        position: absolute;


      }
      .comment-author-comment{
        @media only screen  and (max-width: 599px){
          padding: 4px 8px;
          font-size: 12px;
        }
        @media only screen and (min-width: 600px){
          padding: 20px;
          font-size: 14px;
        }
        border-radius: 2px;
        background:#F2F4FA;
        color: #606060;
        margin-bottom: 10px;
      }
      .comment-content{
        @media only screen and (min-width: 600px){
          padding: 10px;
          font-size: 14px;
        }
        @media only screen  and (max-width: 599px){
          padding: 10px 10px 5px 10px;
          font-size: 12px;
        }
        color: #606060;
      }
      .comment-icon{
        @media only screen and (min-width: 600px){
          font-size: 16px;
          padding: 10px;
        }
        @media only screen  and (max-width: 599px){
          font-size: 14px;
          padding: 4px;
        }
        display: flex;
        color: darkgrey;
        .comment-like-button:hover{
          color: #5995DA!important;
        }
        .comment-like-button{
          transition-duration: 0.5s;
          margin-right: 10px;
          cursor: pointer;
        }
        .comment-subcomment-button:hover{
          color: #5995DA;
        }
        .comment-subcomment-button{
          transition-duration: 0.5s;
          margin-right: 10px;
          cursor: pointer;
        }
        .comment-delete-button:hover{
          color: #5995DA;
        }
        .comment-delete-button{
          transition-duration: 0.5s;
          cursor: pointer;
        }
      }
      .comment-subcomment{
        .comment-subcomment-submit-button:hover{
          background: #4389D9;
        }
        .comment-subcomment-submit-button{
          cursor: pointer;
          padding: 4px 18px;
          background: #5995DA;
          border-radius: 2px;
          color: white;
        }
      }
      .subcomment-input{
        display: none;
        align-items: flex-end;
        .subcomment-submit-button:hover{
          background: #4389D9;
        }
        .subcomment-submit-button{
          cursor: pointer;
          padding: 4px 16px;
          background: #5995DA;
          border-radius: 2px;
          color: white;
        }
      }
    }

  }
}
