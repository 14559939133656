@tcpColor: #4970A2;

.video-root {
  div.tcplayer > video {
    height: auto !important;
    border: 1px solid #e5e5e5;
  }

  .tc-player-container-dimensions {
    height: auto !important;
  }

  .video-js .vjs-tech {
    height: inherit !important;
    max-height: none !important;
    position: relative !important;
    min-height: 230px;
  }

  .video-js {
    height: inherit;
    background: white !important;
  }

  .tcp-vtt-thumbnail-container {
    user-select: none !important;
    pointer-events: none !important;
  }
}


.vjs-chapters-button .vjs-descriptions-button .vjs-subs-caps-button {
  display: none !important;
}

.tcp-skin .tcp-loading-spinner:before {
  border-left-color: @tcpColor !important;
  border-right-color: @tcpColor !important;
}

.vjs-mouse-display {
  z-index: 100000 !important;

  .vjs-time-tooltip {
    font-size: 12px !important;
    opacity: 0.6 !important;
    top: -2.2em !important;
  }
}

.tcp-skin .vjs-volume-panel .vjs-volume-level {
  background-color: @tcpColor !important;
}

.vjs-big-play-button:hover .vjs-button-icon .draw-fill {
  fill: @tcpColor !important;
}

.vjs-progress-holder {
  .vjs-load-progress {
    background: #DDD;
  }

  .vjs-play-progress {
    background: @tcpColor !important;
  }
}

.tcp-right-click-popup-menu {
  display: none;
}


@primary-color: #4970A2;