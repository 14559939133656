.recruit-detail{
  padding: 20px;
  .recruit-back{
    cursor: pointer;
    white-space: pre;
    font-weight: bold;
    color: dimgrey;
    font-size: 16px;
  }
  .recruit-detail-company{
    display: flex;
    @media only screen and (max-width: 799px){
      flex-flow: column;
    }
    .recruit-detail-company-image{
      .company-image{
        @media only screen and (min-width: 1200px){
          width: 220px;
          height: 220px;
        }
        @media only screen and (min-width: 800px) and (max-width: 1199px){
          width: 180px;
          height: 180px;
        }
        @media only screen and (max-width: 799px){
          width: 60%;
        }
      }
      display: flex;
      justify-content: center;
      @media only screen and (min-width: 600px) and (max-width: 799px){
        width: 100%;
        padding: 10px;
      }
      @media only screen and (min-width: 800px){
        width: 30%;
        padding: 20px;
      }
    }
    .recruit-detail-company-des{
      @media only screen and (max-width: 799px){
        width: 100%;
        padding: 10px;
      }
      margin-right: 20px;
      @media only screen and (min-width: 800px){
        width: 70%;
        padding: 20px;
      }
      .recruit-detail-company-name{
        @media only screen and (max-width: 599px){
          font-size: 16px;
        }
        @media only screen and (min-width: 600px){
          font-size: 20px;
        }
        color:#404040;
        margin-bottom: 20px;
      }
      .recruit-detail-people-num{
        border-right: lightgrey solid 1.5px;
        font-size: 14px;
        padding: 0 15px 0 0;
        color: dimgrey;
        line-height: 16px;
        height: 16px;
      }
      .recruit-detail-company-financing{
        font-size: 14px;
        color: dimgrey;
        padding: 0 0 0 15px;
        line-height: 16px;
        height: 16px;
      }
      .recruit-detail-company-address{
        font-size: 14px;
        color: dimgrey;
        margin-bottom: 20px;
      }
      .recruit-detail-company-intro{
        font-size: 14px;
        color: dimgrey;
        word-break: break-all;
      }
    }
  }
  .recruit-detail-content{
    @media only screen and (max-width: 599px){
      flex-flow: column;
    }
    display: flex;
    justify-content: space-between;
    border-bottom: #e8e8e8 solid 1.5px;
    .recruit-detail-des{
      white-space: pre-wrap;
      width: 80%;
      margin-right: 10px;
      @media only screen and (min-width: 1200px){
        padding: 20px;
      }
      @media only screen and (min-width: 600px) and (max-width: 1199px){
        padding: 10px;
      }
      @media only screen and (max-width: 599px){
        padding: 10px;
      }
      .recruit-detail-salary-mobile{
        @media only screen and (min-width: 600px){
          display: none;
        }
        font-size: 16px;
        font-weight: bold;
        color: #1b55f5;
      }
      .recruit-detail-post{
        display: flex;
        align-items: center;
        @media only screen and (min-width: 1200px){
          margin-bottom: 20px;
        }
        @media only screen and (min-width: 600px) and (max-width: 1199px){
          margin-bottom: 10px;
        }
        .recruit-detail-post-name{
          color: #404040;
          @media only screen and (min-width: 1200px){
            font-size: 30px;
          }
          @media only screen and (min-width: 600px) and (max-width: 1199px){
            font-size: 20px;
          }
          @media only screen and (max-width: 599px){
            font-size: 16px;
          }
          font-weight: bold;
        }
      }

      .recruit-detail-requirements{
        font-size: 14px;
        color: dimgrey;
        margin-bottom: 20px;
      }
      .recruit-detail-welfare{
        font-size: 14px;
        color: dimgrey;
        margin-bottom: 10px;
      }
    }
    .recruit-detail-action{
      @media only screen and (min-width: 1200px){
        padding: 20px;
      }
      @media only screen and (min-width: 600px) and (max-width: 1199px){
        padding: 10px;
      }
      @media only screen and (max-width: 599px){
        flex-flow: row;
        justify-content: space-around;
        margin-bottom: 20px;
      }
      flex-flow: column;
      align-items: center;
      display: flex;
      float: right;
      .recruit-detail-salary{
        @media only screen and (min-width: 1200px){
          font-size: 20px;
        }
        @media only screen and (min-width: 600px) and (max-width: 1199px){
          font-size: 16px;
        }
        @media only screen and (max-width: 599px){
          display: none;
        }
        font-weight: bold;
        color: #1b55f5;
        margin-bottom: 10px;
        padding-top: 10px;
      }
      .recruit-detail-submit-resume{
        @media only screen and (max-width: 599px){
          margin-bottom: 0;
        }
        height: 30px;
        display: flex;
        align-items: center;
        cursor: pointer;
        font-weight: bold;
        color: white;
        border-radius: 5px;
        font-size: 14px;
        padding: 18px 10px;
        background: #1b55f5;
        margin-bottom: 20px;
      }
      .recruit-detail-greet{
        @media only screen and (max-width: 599px){
          margin-bottom: 0;
        }
        height: 30px;
        display: flex;
        align-items: center;
        cursor: pointer;
        font-weight: bold;
        color: white;
        border-radius: 5px;
        font-size: 14px;
        padding: 18px 10px;
        background: #02B578;
        margin-bottom: 10px;
      }
    }
  }
}