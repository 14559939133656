.my-gifts-wrapper{
  padding: 20px;
  margin-bottom: 50px;
  .my-gifts-title{
    @media only screen and (min-width: 1025px){
      font-size: 30px;
      padding: 20px;
    }
    @media only screen and (max-width: 1024px) and (min-width: 600px){
      font-size: 25px;
      padding: 15px;
    }
    @media only screen and (max-width: 599px){
      font-size: 18px;
      padding: 10px;
    }

    border-bottom: solid 2px gainsboro;
    margin-bottom: 20px;
  }
  .qrcode-button{
    font-size: 12px;
    cursor: pointer;
    color: darkgrey;
    transition-duration: 0.5s;
  }
  .qrcode-button:hover{
    color: #5995DA;
  }
}