.recruitment-question-recommend-list-card {
  background-color: rgb(252, 252, 252);
  border: 1px solid #f0f0f0;
  border-radius: 8px;
  display: flex;
  align-items: center;
  box-shadow: #d7d7d793 2px 2px 6px;
  padding: 16px;
  width: 100%;

  .card-logo {
    flex-shrink: 0;
    height: 72px;
    width: 158px;
    margin-right: 16px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 4px;
    }
  }

  .card-title {
    font-size: 14px;
    font-weight: bold;
  }

  .card-description {
    font-size: 12px;
    color: grey;
  }
}

.recruitment-question-recommend-list-card:hover {
  box-shadow: #bdbdbd59 6px 6px 16px;
  transition: 0.2s;
  cursor: not-allowed;
}
