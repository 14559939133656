.sidebar {
  background: white;
  position: fixed;
  top: 50px;
  display: flex;
  flex-flow: column;
  height: calc(100vh - 90px);
  @media only screen and (min-width: 1200px) {
    width: 400px;
    left: 0;
  }
  @media only screen and (min-width: 600px) and (max-width: 1199px) {
    width: 400px;
    left: -400px;
  }
  @media only screen and (max-width: 599px) {
    width: 100%;
    left: -100%;
    opacity: 0.9;
  }
  transition-duration: 0.5s;
  z-index: 99;
  border-right: solid hsl(0deg, 0%, 92%) 1px;
  .sidebar-zhuanlan {
    padding: 0 30px;
    position: relative;
    .sidebar-zhuanlan-img {
      transition-duration: 0.5s;
      @media only screen and (min-width: 600px) {
        left: 95px;
      }
      @media only screen and (max-width: 599px) {
        left: calc(50% - 75px);
      }
      position: relative;
      width: 150px;
      left: 95px;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .sidebar-zhuanlan-title {
      font-size: 20px;
      font-weight: bold;
      color: black;
    }
    .sidebar-zhuanlan-intro {
      font-size: 14px;
      color: darkgrey;
      padding-bottom: 10px;
      border-bottom: solid #e8e8e8 1px;
    }
  }
  .sidebar-list {
    @media only screen and (min-width: 600px) {
      padding: 0 30px;
    }
    @media only screen and (max-width: 599px) {
      padding: 0 20px;
    }
    overflow-y: scroll;
    overflow-x: hidden;
    position: relative;
    .sidebar-list-title {
      font-size: 20px;
      padding-left: 10px;
      margin-top: 10px;
      border-left: #5995da solid 3px;
    }
    .zhuanlans-detail-catalog-modules {
      @media only screen and (min-width: 600px) {
        font-size: 20px;
      }
      padding: 0;
    }
    .zhuanlans-detail-catalog-chapters {
      padding: 20px 10px;
      font-size: 14px;
    }
    .zhuanlans-catalog-wrapper {
      margin-bottom: 100px;
    }
  }
}
