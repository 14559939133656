.zhuanlan-render-root{
  padding: 0 !important;
}
.item:last-of-type{
  border:none;
}
.item {
  position: relative;
  border-bottom: solid hsl(0, 0%, 92%) 1px;
  .item-wrapper {
    .categories-name {
      text-align: center;
      a {
        text-decoration: none;
        color: hsl(0, 0%, 30%);
      }
      a:hover {
        border-bottom: solid hsl(0, 0%, 30%) 1px;
      }
      position: relative;
      font-weight: bold;
      text-transform: uppercase;
    }
    position: relative;
    .categories-img-wrapper {
      position: relative;
      text-align: center;
      .categories-img {
        position: relative;
      }
    }
    .categories-content-wrapper {
      position: relative;
      .categories-intro-wrapper {
        .categories-intro {
          word-break: break-word;
          line-height: 25px;
          font-family: Verdana;
          color: dimgrey;
          float: left;
          a {
            text-decoration: none;
          }
          a:hover {
            text-decoration: underline;
          }
          .unopen-wrapper{
            width: 100%;
            .unopen{
              color: #5995DA;
            }
            .appointment{
              cursor: not-allowed;
              float: right;
              color: #5995DA;
            }
          }
          .read-more {
            display: inline-block;
            position: relative;
            white-space: pre;
            color: #5995DA;
            font-family: Verdana;
          }
          .read-more:after {
            border-left: 6px solid #5995DA;
            border-bottom: 4px solid transparent;
            border-top: 4px solid transparent;
            content: "";
            position: absolute;
            top: 10px;
            right: -12px;
            width: 0;
            height: 0;
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 600px) and (max-width: 1399px){
  .item {
    width: 100%;
    height: 350px;
    .item-wrapper {
      height: 210px;
      padding-top: 70px;
      padding-bottom: 70px;
      max-width: 550px;
      margin-left: calc(50% - 275px);
      margin-right: calc(50% - 275px);
      .categories-name {
        font-size: 25px;
      }
      .categories-img-wrapper {
        width: 160px;
        .categories-img {
          width: 160px;
          top: 15px;
        }
      }
      .categories-content-wrapper {
        padding-left: 20px;
        width: 350px;
        height: 180px;
        top: -110px;
        left: 190px;
        .categories-intro-wrapper {
          .categories-intro {
            padding-top: 10px;
            width: 100%;
            font-size: 14px;
            .read-more {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 1400px) {
  .item {
    width: 100%;
    height: 350px;

    .item-wrapper {
      height: 210px;
      padding-top: 70px;
      padding-bottom: 70px;
      max-width: 600px;
      margin-left: calc(50% - 300px);
      margin-right: calc(50% - 300px);
      .categories-name {
        font-size: 25px;
      }
      .categories-img-wrapper {
        width: 160px;

        .categories-img {
          width: 160px;
          top: 15px;
        }
      }

      .categories-content-wrapper {
        padding-left: 20px;
        width: 400px;
        height: 180px;
        top: -110px;
        left: 180px;
        .categories-intro-wrapper {
          .categories-intro {
            padding-top: 10px;
            width: 100%;
            font-size: 14px;

            .read-more {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 599px) {
  .item {
    width: 100%;
    height: 250px;
    padding-bottom: 20px;
    .item-wrapper {
      margin-top: 10px;
      height: 250px;
      .categories-name {
        top: 10px;
        font-size: 18px;
      }

      .categories-img-wrapper {
        width: 100vw;
        height: 105px;

        .categories-img {
          height: 80px;
          top: 25px;
        }
      }

      .categories-content-wrapper {
        width: 100vw;
        height: 145px;


        .categories-intro-wrapper {
          padding-left: 20px;
          padding-right: 20px;

          .categories-intro {
            padding-top: 10px;
            width: 100%;
            font-size: 12px;

            .read-more {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}