.footer{
  z-index: 101;
  height: 40px;
  width: 100%;
  border-top: solid hsl(0, 0%, 92%) 1px;
  position: fixed;
  bottom: 0;
  background: white;
  .copyright{
    a{
      font-size: 12px;
    }
    color: hsl(218,60%,65%);
    position: relative;
    font-size: 14px;
    top: 8px;
    left: 15px;
  }
}