.recruitment-questions {
  position: relative;
  top: 50px;
  height: calc(100vh - 90px);
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  background-color: #f0f0f0;
  .recruitment-question-wrapper {
    overflow-y: scroll;
    overflow-x: hidden;
    border-radius: 6px;
    background-color: white;
    margin: 10px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .recruitment-question-discussion-wrapper {
    overflow-y: scroll;
    overflow-x: hidden;
    border-radius: 6px;
    background-color: white;
    margin: 10px 10px 10px 0;
    .recruitment-question-discussion-content {
      height: calc(100% - 46px); // -navbar
      overflow-y: scroll;
      .recruitment-question-discussion-back-btn {
        border-bottom: 1px solid #f0f0ef;
        padding: 4px 8px;
        .back-btn-text {
          color: grey;
          cursor: pointer;
        }
        .back-btn-text:hover {
          color: black;
        }
      }
      .recruitment-question-discussion-detail-wrapper {
        height: calc(100% - 31px); // - backButton
      }
    }
  }
}

.recruitment-question-discussions-header {
  display: flex;
  justify-content: flex-end;
  padding: 12px 16px 0;
  position: sticky;
  top: 0;
  z-index: 2;
}

.recruitment-question-editor-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;

  // pc
  @media only screen and (min-width: 600px) {
    margin: 0 24px;
    padding-top: 24px;
  }
  // mobile
  @media only screen and (max-width: 599px) {
    margin: 0 16px;
    padding-top: 12px;
  }

  .recruitment-question-editor-wrapper-editor {
    height: 100%;
    .ant-form-item-control {
      height: calc(100%);
      .ant-form-item-control-input {
        height: 100%;
        .ant-form-item-control-input-content {
          height: 100%;
        }
      }
    }
    .ck-editor {
      height: 100%;
    }
    .ck-editor__main {
      height: calc(100% - 40px); // - toolbox
    }
    .ck-editor__editable_inline:not(.ck-comment__input *) {
      min-height: 100%;
      height: 100%;
    }
  }
  .recruitment-question-editor-wrapper-footer {
    flex-shrink: 0;
    flex-grow: 0;
    margin-bottom: 24px;
    text-align: end;
  }
  .recruitment-question-editor-wrapper-footer.answer {
    margin: 16px 0;
  }
}
.recruitment-question-editor-wrapper-drawer .ant-drawer-body {
  padding: 0;
}

.recruitment-question-discussion-detail {
  padding-top: 12px;
  height: 100%;
  .recruitment-question-discussion-detail-main {
    overflow-y: scroll;
    margin: 0 16px;
    height: calc(100% - 31px); // -actionBar

    .recruitment-question-discussion-detail-comment {
      margin-top: 160px;
    }
  }
  .recruitment-question-discussion-detail-action {
    padding: 4px 20px;
    border-top: 1px solid #f0f0ef;
  }
}

// pc
@media only screen and (min-width: 600px) {
  .recruitment-questions {
    .recruitment-question-wrapper {
      width: 50%;
    }
    .recruitment-question-discussion-wrapper {
      width: 50%;
    }
  }
  .sidemenu-item-mobile {
    display: none;
  }
}

// mobile
@media only screen and (max-width: 599px) {
  .recruitment-questions {
    .recruitment-question-wrapper {
      width: 100%;
    }
    .recruitment-question-discussion-wrapper {
      display: none;
    }
    .sidemenu-item-mobile:hover {
      color: #5995da;
      background: #f5faff;
    }
    .sidemenu-item-mobile {
      position: fixed;
      right: 24px;
      opacity: 0.8;
      background: white;
      box-shadow: lightgrey 0px 5px 5px 0px;
      transition-duration: 0.5s;
      text-align: center;
      width: 34px;
      height: 34px;
      border-radius: 100px;
      color: lightgrey;
      cursor: pointer;
      padding: 8px;
    }
    .recruitment-question-answer-edit-btn .sidemenu-item-mobile {
      bottom: 128px;
    }
    .recruitment-question-discussion-btn .sidemenu-item-mobile {
      bottom: 80px;
    }
    .view-recruitment-question-discussion-drawer .ant-drawer-body {
      padding: 0;
    }
  }
}

.recruitment-question-discussion-action-item {
  cursor: pointer;
}
.recruitment-question-discussion-action-item:hover {
  color: #5995da;
}
