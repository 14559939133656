.question-compilation {
  .questions-type {
    font-size: 20px;
    color: #5995da;
    margin-bottom: 20px;
  }
  .questions-type-line {
    position: relative;
    width: calc(100% - 40px);
    background: #5995da;
    height: 2px;
  }
  .questions {
    margin-bottom: 50px;
    padding-left: 25px !important;
    .question-item {
      width: calc(100% - 40px);
      list-style-type: decimal-leading-zero;
      list-style-position: initial;

      span:hover {
        background: #5995da;
        color: white;
      }
      .question-content {
        img {
          max-width: 100%;
        }
        @media only screen and (min-width: 1400px) {
          table {
            max-width: 500px !important;
          }
        }
        @media only screen and (max-width: 1399px) and (min-width: 600px) {
          table {
            width: 400px !important;
          }
        }
        @media only screen and (max-width: 599px) {
          table {
            width: 300px !important;
          }
        }
        font-size: 16px;
        margin-bottom: 50px;
      }
      .answer-wrapper {
        padding: 10px 20px;
        .answer {
          position: relative;
        }
        margin-top: 10px;
        position: relative;
        left: -25px;
        border: solid #5995da 1px;
        width: calc(100% + 25px);
      }
      .angle-down {
        position: relative;
        left: 10px;
      }
      .answer-button {
        cursor: pointer;
        position: relative;
        left: calc(100% - 66px);
        color: #5995da;
        font-size: 14px;
        padding: 2px 20px 2px 10px;
        border: solid #5995da 1px;
      }
      .video-button {
        cursor: pointer;
        position: relative;
        left: calc(100% - 220px);
        color: #5995da;
        font-size: 14px;
        padding: 2px 10px;
        border: solid #5995da 1px;
      }
      .video-button-lock {
        cursor: not-allowed;
        position: relative;
        left: calc(100% - 240px);
        color: #5995da;
        font-size: 14px;
        padding: 2px 10px;
        border: solid #5995da 1px;
      }
      .question-item-line {
        margin-top: 20px;
        left: -25px;
        position: relative;
        background: #5995da;
        height: 1px;
        width: calc(100% + 25px);
      }
    }
  }
}
