.userinfo-wrapper {
  position: relative;
  @media only screen and (min-width: 620px) {
    top: 50px;
  }
  padding-bottom: 100px;
  .userinfo {
    @media only screen and (min-width: 1400px) {
      width: 800px;
      left: calc(50% - 400px);
    }
    @media only screen and (max-width: 1399px) and (min-width: 620px) {
      width: 600px;
      left: calc(50% - 300px);
    }
    @media only screen and (max-width: 619px) {
      width: 90%;
      left: 5%;
    }
    .section{
      border-top:solid #5995DA 1px;
      padding: 10px;
    }
    position: relative;
    .edit-icon{
      cursor: pointer;
      width: 20px;
      left: calc(100% - 20px);
      position: relative;
      color: #5995DA;

    }
    .userinfo-title {
      margin-top: 100px;
      margin-bottom: 10px;
      color: #5995DA;
      text-align: center;
      @media only screen and (max-width: 619px) {
        font-size: 25px;
      }
      @media only screen and (min-width: 620px) {
        font-size: 30px;
      }
    }
    .userinfo-list {
      color: #5995DA;
      @media only screen and (max-width: 619px) {
        font-size: 16px;
      }
      @media only screen and (min-width: 620px) {
        font-size: 20px;
      }
      margin-bottom: 20px;

      input::-webkit-input-placeholder {
        color: lightgray;
      }

      input {
        font-size: 14px;
        border: solid lightgray 1px;
        height: 20px;
        width: 200px;
        padding-left: 10px;
        padding-right: 10px;
      }

      input:hover {
        border: solid #5995DA 1px;
      }

      input:focus {
        outline: none;
        border: solid #5995DA 1px;
      }

      li {
        padding: 10px;
        list-style: none;
        background: #fafafa;
        margin-bottom: 5px;
      }
    }

    .buttons {
      text-align: center;

      .button {
        cursor: pointer;
        padding: 6px 20px;
        margin: 4px 6px;
        border: 1px solid #5995DA;
        display: inline-block;
        color: #5995DA;
        background: white;
        &:hover{
          background: #5995DA;
          color: white;
        }
        &.button-primary {
          background: #5995DA;
          color: white;
          &:hover {
            background: white;
            color: #5995DA;

          }
        }
      }

      //.button.button-primary{
      //
      //}
    }


    .buy-testpaper {
      .buy-testpaper-title {
        color: #5995DA;
        padding: 10px;
        @media only screen and (max-width: 619px) {
          font-size: 16px;
        }
        @media only screen and (min-width: 620px) {
          font-size: 20px;
        }
      }
    }

    .buy-testpaper-collection {
      .buy-testpaper-collection-title {
        color: #5995DA;
        @media only screen and (max-width: 619px) {
          font-size: 16px;
        }
        @media only screen and (min-width: 620px) {
          font-size: 20px;
        }
        padding: 10px;
      }
    }
  }
}