.ck-editor__editable_inline {
  min-height: 400px;
}
.discuss-create{
  position: relative;
  top: 50px;
  height: calc(100vh - 90px);
  overflow-y: scroll;
  overflow-x: hidden;
  .discuss-create-wrapper{
    .discuss-title-input{
      font-size: 36px;
      color: #7B7B7B;
      margin-bottom: 10px;
    }
    @media only screen and (max-width: 599px) {
      padding: 6px 10px;
    }
    @media only screen and (min-width: 600px) and (max-width: 1199px) {
      padding: 20px 60px;
    }
     padding: 20px 200px;
    .discuss-create-bottom-bar{
      @media only screen and (max-width: 599px) {
        flex-flow: column;
        align-items: flex-start;
      }
      padding: 20px 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .tag-selector{
        display: flex;
        flex: 1;
        align-items: center;
        .default-tag{
          margin-right: 10px;
          min-width: 50px;
          overflow: auto;
        }
        .add-custom-tag{
          cursor: pointer;
          color: #5189FF;
        }
        .tag-title{
          flex-shrink: 0;
          @media only screen and (max-width: 599px) {
            font-size: 14px;
          }
          color: #7B7B7B;
          font-size: 16px;
        }
        .tag-delete{
          font-size: 12px;
          transition: 0.3s all;
          :hover{
            color: #404040;
          }
        }
        .discuss-tag{
          flex-shrink: 0;
          @media only screen and (max-width: 599px) {
            font-size: 12px;
            padding: 2px 6px;
            margin-right: 4px;
          }
          cursor: pointer;
          font-size: 14px;
          border: 1px solid #D8D8D8;
          padding: 2px 10px;
          border-radius: 8px;
          color: #D8D8D8;
          margin-right: 12px;
          transition: 0.3s;
          &:last-of-type{
            margin-right: 0;
          }
          &:hover{
            border: 1px solid #7B7B7B;
            color: #7B7B7B;
          }
          &-selected{
            @media only screen and (max-width: 599px) {
              font-size: 12px;
              padding: 2px 6px;
              margin-right: 4px;
            }
            transition: 0.3s;
            cursor: pointer;
            font-size: 14px;
            padding: 2px 10px;
            border-radius: 8px;
            margin-right: 12px;
            border: 1px solid #5189FF;
            color: white;
            background: #5189FF;
          }
        }
      }
      .discuss-action{
        @media only screen and (max-width: 599px) {
          margin-top: 20px;
          justify-content: space-evenly;
          width: 100%;
        }
        display: flex;
        .action-button{
          margin-right: 10px;
          color: white;
          padding: 4px 10px;
          border-radius: 8px;
          cursor: pointer;
          transition:0.3s all;
          &:hover{
            opacity: 0.9;
          }
        }
      }
    }
  }
}
