.navigation-menu{
  position: relative;
  display: flex;
  justify-content: center;
  @media only  screen and (max-width: 599px){
    margin-bottom: 10px;
  }
  margin-bottom: 20px;
  a{
    color: black;
  }
  .navigation-menu-item{
    @media only  screen and (max-width: 599px){
      font-size: 16px;
      margin-right: 20px;
    }
    font-size: 20px;
    margin:0 40px;
    transition-duration: 0.3s;
    cursor: pointer;
  }
  .navigation-menu-item:hover{
    color: #486889;
  }
  .navigation-menu-item:last-of-type{
    margin-right: 0;
  }
  .navigation-menu-item-activated{
    @media only  screen and (max-width: 600px){
      font-size: 16px;
      margin-right: 20px;
    }
    cursor: pointer;
    font-size: 20px;
    margin:0 40px;
    color: #486889;
  }
  .menu-activated-bar{
    position: absolute;
    height: 6px;
    width: 30px;
    bottom: -10px;
    background: #486889;
    border-radius: 4px;
    transition-duration: 0.3s;
  }
  .navigation-menu-item-activated:last-of-type{
    margin-right: 0;
  }
}
