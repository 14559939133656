.my-discuss-wrapper{
  @media only screen and (max-width: 599px) {
    padding: 20px;
  }
  padding: 40px 100px 40px 40px;
  .discuss-top-action-bar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .discuss-button-selected,.draft-button-selected{
      @media only screen and (max-width: 599px) {
        font-size: 14px;
        margin-right: 10px;
        padding: 2px 6px;
      }
      font-size: 16px;
      letter-spacing: 0.2em;
      font-weight: 500;
      background: #767676;
      border-radius: 8px;
      cursor: pointer;
      margin-right: 20px;
      color: white;
      padding: 4px 12px;
    }

    .draft-button,.discuss-button{
      @media only screen and (max-width: 599px) {
        font-size: 14px;
        margin-right: 10px;
        padding: 2px 6px;
      }
      font-size: 16px;
      letter-spacing: 0.2em;
      font-weight: 500;
      margin-right: 20px;
      border: 1px solid #7B7B7B;
      background: #ffffff;
      border-radius: 8px;
      color: #7B7B7B;
      cursor: pointer;
      padding: 4px 12px;
    }
    .submit-button{
      @media only screen and (max-width: 599px) {
        font-size: 14px;
        padding: 4px 12px;
      }
      cursor: pointer;
      border-radius: 10px;
      font-size: 16px;
      padding:6px 12px;
      transition: 0.3s;
      color: white;
      &:hover{
        opacity: 0.9;
      }
    }
  }

}
