.teachers-wrapper{
  position: relative;
  top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 90px);
  .teachers-title{
    @media only screen and (max-width: 499px){
      width: 90%;
      font-size: 20px;
      padding: 20px 0 0 20px;
    }
    @media only screen and (min-width: 500px) {
      width: 500px;
      font-size: 25px;
      padding: 20px 0 0 20px;
    }

  }
  .teachers-content{
    @media only screen and (max-width: 499px){
      width: 90%;
    }
    @media only screen and (min-width: 500px) {
      width: 500px;
    }
    .teacher{
      margin-top: 20px;
      box-shadow: #dedede 0 0 15px 0;
      display: grid;
      @media only screen and (max-width: 499px){
        grid-template-columns: 120px calc(100% - 120px);
      }

      @media only screen and (min-width: 500px){
        grid-template-columns: 150px auto 130px;
        grid-template-rows: 50px 16px 39px 60px;
      }
      align-items: center;
      border: solid 1px gainsboro;
      border-radius: 20px;
      .teacher-avatar{
        background-image: url("https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT4aQho8HdWbxfZsSzp59JOWqG7tVEF0B_rKw&usqp=CAU");
        background-size: cover;
        grid-row: 1 / 5;
        margin: 0 15px 0 15px;
        border-radius: 20px;
        @media only screen and (max-width: 499px){
          height: 100px;
          width: 90px;
        }
        @media only screen and (min-width: 500px){
          height: 140px;
          width: 120px;
        }
      }
      .teacher-name{
        @media only screen and (min-width: 500px){
          font-size: 20px;
        }
        @media only screen and (max-width: 499px){
          font-size: 16px;
          padding-top: 10px;
          padding-bottom: 10px;
        }
      }
      .teacher-consult-info{
        display: flex;
        align-items: center;
        @media only screen and (min-width: 500px){
          font-size: 14px;
        }
        @media only screen and (max-width: 499px){
          font-size: 12px;
        }

        color: #8a8a8a;
        .info-text{
          padding-left: 2px;
          @media only screen and (min-width: 500px){
            width: 90px;
          }
          @media only screen and (max-width: 499px){
            width: 70px;
          }

        }
      }
      .teacher-position{
        color: gray;
        @media only screen and (min-width: 500px){
          font-size: 14px;
        }
        @media only screen and (max-width: 499px){
          font-size: 12px;
        }
      }
      .teacher-introduction{
        // margin-top: 5px;
        height: 100%;
        @media only screen and (min-width: 500px){
          font-size: 14px;
        }
        @media only screen and (max-width: 499px){
          font-size: 12px;
          position: relative;
          padding-right: 10px;
          top: 10px;
        }
      }
      .ask-button{
        @media only screen and (min-width: 500px){
          grid-column: 3 / 4;
          grid-row: 1 / 5;
          text-align: center;
          text-decoration: none;
          span{
            padding: 8px 12px 8px 12px;
            border-radius: 10px;
            color: white;
            background-color: rgb(30, 110, 205);
          }
          span:hover{
            cursor: pointer;
          }
        }
        @media only screen and (max-width: 499px){
          margin-bottom: 10px;
          font-size: 12px;
          position: relative;
          left: calc(50% - 33px);
          span{
            padding: 6px 8px 6px 8px;
            border-radius: 10px;
            color: white;
            background-color: rgb(30, 110, 205);
          }
          span:hover{
            cursor: pointer;
          }
        }
      }
    }
  }
}