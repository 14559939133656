.modules-wrapper{
  ul{
    margin: 0;
    padding: 0;
  }
}
.zhuanlan-render-root{
  padding: 0 !important;
}
.gift-icon:hover{
  color: gray;
}
.gift-icon{
  transition-duration: .2s;
  cursor: pointer;
  font-size: 14px;
  position: absolute;
  color: darkgrey;
  top:10px;
  @media only screen and (min-width: 1200px){
    right: 210px;
  }
  @media only screen and (max-width: 1199px){
    right: 10px;
  }
}
.modules{
  .modules-name-wrapper{
    position: relative;
    text-align: center;
    border-bottom: solid hsl(0, 0%, 92%) 1px;
    .hook-position-modules{
      position: relative;
      height: 0;
      top: -110px
    }
    .modules-name{
      list-style-type:  upper-roman;
      list-style-position: inside;
      position: relative;
      font-family: Verdana;
      color: hsl(0,0%,30%);
    }
  }
  .chapters-wrapper{
    .chapters{
      position: relative;
      border-bottom: solid hsl(0, 0%, 92%) 1px;
      .chapters-name-wrapper{
        text-align: center;
        .hook-position-chapters{
          position: relative;
          height: 0;
          top: -100px;
        }
        .chapters-name{
          font-weight: bold;
          font-family: Verdana;
          color: hsl(0,0%,35%);
        }
      }
      .chapters-content-wrapper{
        position: relative;
        .lock-info{
          font-size: 14px;
          color: gray;
          text-align: center;
          .lock-img{
            font-weight: lighter;
            margin-left: 10px;
          }
        }
        .chapters-content{
          font-family: Verdana;
          color: hsl(0,0%,35%);
          line-height: 25px;
        }
      }
    }
  }
}
@media only screen and (min-width: 600px) and (max-width: 1199px){
  .modules{
    .modules-name-wrapper{
      padding-bottom: 20px;
      padding-top: 20px;
      .hook-position-modules{
        position: relative;
        height: 0;
        top: -90px
      }
      .modules-name{
        font-size: 30px;
      }
    }
    .chapters-wrapper{
      .chapters{
        padding-top: 20px;
        padding-bottom: 20px;
        .chapters-name-wrapper{
          .hook-position-chapters{
            height: 0;
            top: -100px;
          }
          .chapters-name{
            font-size: 25px;
          }
        }
        .chapters-content-wrapper{
          max-width: 550px;
          left: calc(50vw - 275px);
          padding-top: 30px;
          .chapters-content{
            font-size: 14px;
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 1200px){
  .modules{
    .modules-name-wrapper{
      width: 100%;
      padding-bottom: 50px;
      padding-top: 50px;
      .hook-position-modules{
        position: relative;
        height: 0;
        top: -110px
      }
      .modules-name{
        width: calc(100% - 800px);
        left: 400px;
        font-size: 35px;
      }
    }
    .chapters-wrapper{
      .chapters{
        position: relative;
        top:0;
        padding-top: 50px;
        padding-bottom: 50px;
        .chapters-name-wrapper{
          .hook-position-chapters{
            height: 0;
            top: -100px;
          }
          .chapters-name{
            font-size: 30px;
          }
        }
        .chapters-content-wrapper{
          max-width: 600px;
          left: calc(50vw - 300px);
          padding-top: 30px;
          .chapters-content{
            font-size: 14px;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 599px){
  .modules{
    .modules-name-wrapper{
      width: 100vw;
      padding-bottom: 20px;
      padding-top: 20px;
      .hook-position-modules{
        position: relative;
        height: 0;
        top: -80px
      }
      .modules-name{
        font-size: 20px;
      }
    }
    .chapters-wrapper{
      .chapters{
        padding-top: 20px;
        padding-bottom: 20px;
        .chapters-name-wrapper{
          text-align: center;
          .hook-position-chapters{
            height: 0;
            top: -70px;
          }
          .chapters-name{
            font-size: 16px;
            font-weight: bold;
          }
        }
        .chapters-content-wrapper{
          width: 100vw;
          padding-top: 10px;
          .chapters-content{
            padding-right: 20px;
            padding-left: 20px;
            font-size: 12px;
          }
        }
      }
    }
  }
}
.qrcode-wrapper{
  text-align: center;
  .qrcode{
  }
}



