.zhuanlans-catalog-wrapper{
}
.zhuanlans-detail-catalog{
  .zhuanlans-detail-catalog-modules{
    background: white;
    @media only screen and (max-width: 600px){
      font-size: 16px;
    }
    font-size: 20px;
    border: none !important;
    .ant-collapse-header:hover{
      text-decoration: underline black 1.5px;
    }
    .zhuanlans-detail-catalog-chapters{
      @media only screen and (max-width: 600px){
        font-size: 14px;
        padding: 16px 8px;
      }
      position: relative;
      cursor: pointer;
      color: darkgrey;
      padding: 20px;
      width: 100%;
      @media only screen and (min-width: 1200px){
        font-size: 16px;
      }
      @media only  screen and (min-width: 600px) and (max-width: 1199px){
        font-size: 14px;
      }
      transition-duration: 0.5s;
      border-radius: 5px;
    }
    .zhuanlans-detail-catalog-chapters-navlink{
    }
    .zhuanlans-detail-catalog-chapters-navlink.active{
      .zhuanlans-detail-catalog-chapters{
        color: #5995DA;
        background: #f5faff;
      }
    }

    .zhuanlans-detail-catalog-chapters:hover{
      color: #5995DA;
      background: #f5faff;
    }
  }
}