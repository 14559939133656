
  .testpaper-item{
    margin-bottom: 10px;
    padding: 10px;
    background: #fafafa;
    color: black;
    .lock-icon{
      position: absolute;
      right: 20px;
      margin-top: 5px;
      color: lightgrey;
      width: 20px;
    }
    a{
      text-decoration: none;
    }
    a:hover{
      color: #5995DA;
      text-underline: #5995DA;
    }
    .testpaper-item-title{
      color: black;
      font-size: 20px;
    }
  }