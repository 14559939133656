.message{
   padding: 20px;
  .message-item:last-of-type{
    border: none;
  }
  .message-item:hover{
    background: #f5faff;
  }
  .message-item{
    position: relative;
    .message-delete:hover{
      color: #5995DA;
    }
    .message-delete{
      cursor: pointer;
      z-index: 6;
      transition-duration: 0.3s;
      font-size: 12px;
      color:darkgrey;
      position: absolute;
      @media only screen and (max-width: 599px){
        display: block;
        transform: translateY(-18.5px) translateX(170px);
      }
      @media only screen and (min-width: 600px) {
        display: none;
        transform: translateY(-19px) translateX(200px);
      }
    }
    z-index: 5;
    cursor: pointer;
    border-radius: 5px;
    @media only screen and (min-width: 600px){
      padding: 20px 20px 10px 20px;
    }
    @media only screen  and (max-width: 599px){
      padding: 10px 10px 10px 10px;
    }
    border-bottom: solid #e8e8e8 1px;
    .message-user-avatar{
      position: relative;
      flex-shrink: 0;
      @media only screen  and (max-width: 599px){
        margin-right: 10px;
      }
      @media only screen and (min-width: 600px){
        margin-right: 20px;
      }
    }
    .message-body{
      position: relative;
      .message-user-name{
        position: relative;
        @media only screen and (min-width: 600px){
          font-size: 16px;
        }
        @media only screen  and (max-width: 599px){
          font-size: 14px;
        }
        color: #404040;
      }
      .message-desc{
        @media only screen and (max-width: 599px){
          font-size: 12px;
        }
        @media only screen and (min-width: 600px){
          font-size: 14px;
        }
        margin-left: 10px;
        color: darkgrey;
      }
      .message-content{
        @media only screen and (min-width: 600px){
          padding: 10px;
        }
        @media only screen  and (max-width: 599px){
          padding: 10px 10px 5px 10px;
        }
        font-size: 14px;
        color: #606060;
      }
      .message-date{
        font-size: 12px;
        color: darkgrey;
      }
    }
  }
}