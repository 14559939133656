.resume-form-title{
  display: flex;
  justify-content: space-between;
  margin: 20px 0 60px;

  .resume-form-title-label{
    border-left: 6px solid rgb(16, 50, 143);
    padding-left: 10px;
    margin-left: 20px;
    font-size: 24px;
    font-weight: bold;
  }

  .resume-form-title-progress{
    font-size: 24px;
    font-weight: bold;
    color: rgb(172, 172, 172);
  }
}

.resume-form-content{
  margin: 0 50px;
}