.zhuanlans{
  position: relative;
  top: 50px;
  scroll-behavior: smooth;
  @supports (-webkit-appearance: none) {
    height: calc(100dvh - 90px);
  }
  height: calc(100vh - 90px);
  overflow-y:scroll;
  .zhuanlans-wrapper{
    position: relative;
    border-bottom: solid hsl(0, 0%, 92%) 1px;
    .zhuanlans-img-wrapper{
      text-align: center;
      .zhuanlans-img{
        position: relative;
      }
    }
    .zhuanlans-name-wrapper{
      text-align: center;
      .zhuanlans-name{
        color: hsl(0,0%,35%);
        position: relative;
        font-family: Verdana;
        font-weight: bold;
      }
    }
    .zhuanlans-intro-wrapper{
      .purchase-wrapper{
        @media only screen and (min-width: 600px) {
          margin-top: 50px;
        }
        @media only screen and (max-width: 599px) {
          margin-top: 20px;
        }
        text-align: center;
        .price{
          @media only screen and (min-width: 1400px){
            left: -100px;
            font-size: 22px;
          }
          @media only screen and (min-width: 600px) and (max-width: 1399px){
            left: -110px;
            font-size: 20px;
          }
          @media only screen and (max-width: 599px){
            font-size: 14px;
            left: -60px;
          }
          position: relative;
          font-family: Verdana;
          font-weight: bold;

          color: hsl(0,0%,35%);
        }
        .purchase-frequency{
          @media only screen and (min-width: 1400px){
            left: -100px;
          }
          @media only screen and (min-width: 600px) and (max-width: 1399px){
            left: -110px;
          }
          @media only screen and (max-width: 599px){
            font-size: 12px;
            left: -60px;
          }
          color: hsl(0,0%,35%);
          position: relative;
        }
        .purchase-button:hover{
          cursor: pointer;
          color: white;
          background: #5995DA;
        }
        .purchase-button{
          @media only screen and (min-width: 600px) and (max-width: 1399px) {
            left: calc(50% - 210px);
            width: 150px;
          }
          @media only  screen  and (min-width: 1400px){
            left: calc(50% - 220px);
            width: 150px;
          }
          @media only screen and (max-width: 599px){
            left: 65px;
            width: 120px;
            letter-spacing: 2px;
            top: -35px;
            font-size: 14px;
            padding: 4px 8px;
          }
          top: -45px;
          text-align: center;
          letter-spacing: 5px;
          padding: 6px 12px 6px 12px;
          position: relative;
          font-size: 16px;
          background: white;
          border: solid #5995DA 1px;
          color: #5995DA;
        }
      }
      position: relative;
      padding-top: 20px;
      .zhuanlans-intro{
        line-height: 25px;
        font-family: Verdana;
        position: relative;
        color: dimgrey;
      }
    }
  }
  .zhuanlans-detail{
    @media only  screen and (min-width: 600px){
      padding: 20px;
    }
    @media only screen and (max-width: 599px) {
      padding: 10px;
    }
    max-width: 800px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    .zhuanlan-detail-content{
      position: relative;
    }
  }
}
@media only screen and (min-width: 600px) and (max-width: 1199px){
  .sidebar-mobile{
    overflow-y: auto;
    .r-angle{
      z-index: 6;
      position: fixed;
      right: 10px;
      top:70px;
      font-size: 20px;
    }
    .l-angle{
      z-index: 6;
      position: fixed;
      right: 10px;
      top:70px;
      font-size: 20px;
    }
  }
  .zhuanlans{
    width: 100%;
    padding-bottom: 50px;
    .zhuanlans-wrapper{
      padding-bottom: 30px;
      padding-top: 20px;
      .zhuanlans-img-wrapper{
        .zhuanlans-img{
          width: 300px;
        }
      }
      .zhuanlans-name-wrapper{
        .zhuanlans-name{
          font-size: 35px;
        }
      }
      .zhuanlans-intro-wrapper{
        max-width: 550px;
        left: calc(50vw - 275px);
        .zhuanlans-intro{
          line-height: 25px;
          font-size: 14px;
        }
      }
    }
    .sidebar-pc{
      visibility: hidden;
      height: 0;
    }
    .chapters-wrapper{
      ul{
        margin: 0;
        padding: 0;
      }
    }
  }
}
@media only screen and (min-width: 1200px){
  .sidebar-mobile{
    height: 0;
    //visibility: hidden;
    display: none;
  }
  .sidebar-pc{
    z-index: 6;
    width: 200px;
    position: -webkit-sticky;
    position: sticky;
    left: calc(100% - 200px);
    display: block;
    top:-50px;
  }
  .zhuanlans{
    width: 100%;
    padding-bottom: 100px;
    .zhuanlans-wrapper{
      padding-bottom: 40px;
      padding-top: 20px;
      .zhuanlans-img-wrapper{
        .zhuanlans-img{
          width: 300px;
        }
      }
      .zhuanlans-name-wrapper{
        .zhuanlans-name{
          font-size: 40px;
        }
      }
      .zhuanlans-intro-wrapper{
        max-width: 600px;
        left: calc(50vw - 300px);
        .zhuanlans-intro{
          line-height: 25px;
          font-size: 14px;
        }
      }
    }
    .chapters-wrapper{
      position: relative;
      margin-top:0px;
      ul{
        margin: 0;
        padding: 0;
      }
    }
  }
}
@media only screen and (max-width: 599px){
  .sidebar-mobile{
    overflow-y: auto;
    .r-angle{
      z-index: 6;
      position: fixed;
      right: 10px;
      top:70px;
      font-size: 20px;
    }
    .l-angle{
      z-index: 6;
      position: fixed;
      right: 10px;
      top:70px;
      font-size: 20px;
    }
  }
  .zhuanlans{
    width: 100vw;
    padding-bottom: 50px;
    .zhuanlans-wrapper{
      padding-bottom: 20px;
      .zhuanlans-img-wrapper{
        .zhuanlans-img{
          width: 200px;
        }
      }
      .zhuanlans-name-wrapper{
        .zhuanlans-name{
          font-size: 20px;
        }
      }
      .zhuanlans-intro-wrapper{
        width: 100vw;
        .zhuanlans-intro{
          padding-right: 20px;
          padding-left: 20px;
          line-height: 25px;
          font-size: 12px;
        }
      }
    }
    .sidebar-pc{
      visibility: hidden;

      height: 0;
    }
    .chapters-wrapper{
      ul{
        margin: 0;
        padding: 0;
      }
    }
  }
}
