.my-rights-wrapper{
    padding: 20px;
    .my-rights-top-bar{
        position: relative;
        .my-rights-level-icon{
            position: absolute;
            @media only screen and (max-width: 1200px) {
                width: 120px;
                left: 70px;
                top: 10px;
            }
            width: 150px;
            left: 100px;
        }
        .my-rights-notification{
            position: absolute;
            @media only screen and (min-width: 600px) and (max-width: 1200px) {
                right: 20px;
                top:10px;
            }
            @media only screen and (max-width: 599px){
                right: 15px;
                top:10px;
            }
            right: 40px;
            top: 30px;
            color: #606060;
            font-size: 12px;
        }
        @media only screen and (max-width: 1200px) {
            padding: 10px;
        }
        border-radius: 10px;
        box-shadow: 0 2px 10px 0 rgba(46, 61, 72, 0.05);
        display: flex;
        padding: 20px;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        .my-rights-avatar{
            position: relative;
        }
        .my-rights-name-wrapper{
            margin-top: 10px;
            align-items: center;
            justify-content: center;
            display: flex;
            .my-rights-name{
                font-size: 20px;
                color: #606060;
            }
            .my-rights-tag{
                margin-left: 10px;
                background: #2db7f5;
                color: white;
                padding: 0 4px;
                border-radius: 5px;
                font-size: 12px;
            }
        }
        .my-rights-progress{
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px;
            @media only screen and (min-width: 600px) and (max-width: 1199px) {
                width: 450px;
                max-width: calc(100% - 40px);
            }
            @media only screen and (min-width: 1200px ){
                width: 600px;
            }
            @media only screen and (max-width: 599px){
                width: calc(100% - 20px);
            }
            .progress-text{
                margin-left: 10px;
                color: #606060;
                font-size: 12px;
            }
        }
    }
    .rights-title{
        padding: 10px;
        font-weight: bold;
        font-size: 16px;
        color: #404040;
    }
    .my-rights{
        .rights-list{
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            gap:40px;
            @media only screen and (max-width: 599px){
                gap: 20px;
            }
        }
    }
}
