.rights-modal-content{
  max-height: 300px;
  overflow: auto;
  .rights-modal-description{
    text-align: center;
    color: darkgrey;
    font-size: 12px;
  }
}

.rights-item-wrapper{
  width: 140px;
  border-radius: 10px;
  display: flex;
  flex-flow: column;
  align-items: center;
  //justify-content: space-between;
  padding: 10px 20px;
  margin-bottom: 10px;
  background: #f5faff;
  position: relative;
  cursor: pointer;
  &.disabled{
    cursor: auto;
    background: #fafafa;
  }
  .rights-item-icon{
    width: 100px;
    height: 75px;
    color: #5995DA;
    &.disabled{
      filter: grayscale(100%);
    }
  }
  .rights-item-title{
    font-weight: bold;
    font-size: 16px;
    color: #404040;
  }
  .rights-item-level{
    margin-bottom: 20px;
    font-size: 25px;
    color: #5995DA;
    &.disabled{
      color: lightgrey;
    }
  }
}
.rights-item-description{
  margin-top: 10px;
  font-size: 14px;
  color: lightgrey;
  width: 140px;
  text-align: center;
}
