.login-wrapper{
  position: relative;
  top: 50px;
  @media only screen and (min-width: 1024px) {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    height: calc(100vh - 90px);
  }
  @media only screen and (min-width: 620px) and (max-width: 1023px){
    .user-frame{
      left: calc(50% - 200px);
    }
  }
  @media only screen and (max-width: 619px){
    .user-frame{
      width: 90% !important;
      left: 5%;
      border: none !important;
    }
  }
  .logo{
    @media only screen and (min-width: 1024px) {
      img{
        width: 500px;
      }
    }
    @media only screen and (min-width: 620px) and (max-width: 1023px){
      img{
        margin-top: 20px;
        width: 300px;
        margin-bottom: 20px;
        position: relative;
        left: calc(50% - 150px);
      }
    }
    @media only screen and (max-width: 619px){
      img{
        width: 200px;
        position: relative;
        margin-top: 20px;
        left: calc(50% - 100px);
      }
    }
  }
  .user-frame{
    position: relative;
    width: 400px;
    height: 480px;
    border: #9d9d9d solid 2px;
    border-radius: 20px;
    padding: 30px;
    .user-title{
      font-size: 30px;
      font-weight: 700;
      color: rgb(16, 51, 144);
    }
    .mobile{
      span{
        border: none;
        background-color: white;
        font-size: 18px;
        color: dimgray;
        svg{
          font-size: 12px;
        }
      }
    }
    .sms{
      input{
        width: calc(100% - 90px);
      }
      .button{
        color: rgb(45, 86, 221);
      }
      .button:hover{
        cursor: pointer;
      }
      .disabled-button{
        display:inline-block;
        position: relative;
        width: 100px;
        top: -30px;
        left: calc(100% - 90px);
      }
      .disabled-button:hover{
        cursor: not-allowed;
      }
    }
    .user-input{
      height: 50px;
      input{
        font-size: 18px;
      }
      border-bottom:gainsboro 1px solid;
      margin: 30px 0;
    }
    .user-input:hover{
      border-bottom:rgb(45, 86, 221) 1px solid;
    }
    .user-long-button-loading{
      width: 100%;
      height: 40px;
      font-size: 16px;
      background-color: lightgrey;
      color: white;
      border-radius: 20px 20px 20px 20px;
      border: none;
    }
    .user-long-button{
      width: 100%;
      height: 40px;
      font-size: 16px;
      background-color: rgb(16, 51, 144);
      color: white;
      border-radius: 20px 20px 20px 20px;
      border: none;
      cursor: pointer;
    }
    .user-policy{
      margin-top: 15px;
      a{
        color: #6d6d6d;
      }
      a:hover{
        text-decoration: underline;
      }
    }
    .third-party-login{
      position: absolute;
      bottom: 0;
      width: calc(100% - 2 * 30px);
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #9d9d9d;
      .third-party-links{
        margin: 20px 0 30px 0;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        width: 100%;
        .circle{
          border: 1px solid;
          border-radius: 50% 50% 50% 50%;
          height: 32px;
          width: 32px;
          svg{
            margin: 3px;
            height: 24px;
            width: 24px;
          }
        }
        .wechat{
          color: rgb(70, 187, 54);
        }
      }
    }
  }
}