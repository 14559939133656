.discuss{
  position: relative;
  top: 50px;
  height: calc(100vh - 90px);
  overflow-y: scroll;
  overflow-x: hidden;
  .discuss-wrapper{
    @media only screen and (max-width: 599px) {
      padding: 10px;
    }
    @media only screen and (min-width: 600px) and (max-width: 1199px) {
      padding: 20px 60px;
    }
    @media only screen and (min-width: 1200px) {
      padding: 40px 200px;
    }
    .discuss-title-bar{
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media only screen and (max-width: 1199px) {
        flex-flow: column;
        align-items: flex-start;
      }
      .discuss-action-wrapper{
        display: flex;
        align-items: center;
        @media only screen and (max-width: 1199px) {
          width: 100%;
          justify-content: space-between;
        }
        .discuss-button-wrapper{
          @media only screen and  (max-width: 1199px) {
            display: flex;
          }
          .discuss-button-icon{
            margin-right: 4px;
            @media only screen and (max-width: 599px) {
              margin-right: 0;
            }
          }
          .discuss-button-text{
            @media only screen and (max-width: 599px) {
              display: none;
            }
          }
        }
      }
      .discuss-title{
        width: 250px;
        @media only screen and (max-width: 599px) {
          font-size: 24px;
        }
        @media only screen and (min-width: 600px) and (max-width: 1199px) {
          font-size: 28px;
        }
        @media only screen and (min-width: 1200px) {
          font-size: 36px;
        }
        font-weight: 500;
        letter-spacing: 0.08em;
        color: #7B7B7B;
      }
      .discuss-button{
        @media only screen and  (max-width: 599px) {
          padding:4px 8px;
        }
        cursor: pointer;
        border-radius: 10px;
        font-size: 16px;
        padding:6px 12px;
        transition: 0.3s;
        color: white;
        &:hover{
         opacity: 0.9;
        }
      }
    }
    .discuss-content{
      margin-top: 10px;
    }
    .discuss-tag-bar{
      margin-top: 25px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .discuss-tag{
        font-weight: bold;
        cursor: pointer;
        @media only screen and  (max-width: 599px) {
          font-size: 14px;
          padding: 4px 8px;
          margin-right: 6px;
        }
        padding: 6px 12px;
        margin-right: 12px;
        border-radius: 8px;
        font-size: 16px;
        color: #767676;
        border: 1px solid #D8D8D8;
        transition: 0.3s;
        &:hover{
          border: 1px solid #7B7B7B;
          color: #7B7B7B;
        }
        &-selected{
          font-weight: bold;
          cursor: pointer;
          padding: 6px 12px;
          margin-right: 12px;
          border-radius: 8px;
          font-size: 16px;
          transition: 0.3s;
          border: 1px solid #5189FF;
          color: white;
          background: #5189FF;
        }
      }
      .sort-select{
        color:#7B7B7B;
      }
    }
  }
}
