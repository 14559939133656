.sidemenu-wrapper {
  position: relative;
  width: 200px;
  z-index: 98;
}
.sidemenu {
  transition-duration: 0.5s;
  @media only screen and (min-width: 750px) and (max-width: 1199px) {
    left: calc(50% + 315px);
  }
  @media only screen and (min-width: 1200px) {
    left: calc(50% + 550px);
  }
  @media only screen and (max-width: 749px) {
    display: none;
  }
  padding: 5px;
  border-radius: 30px;
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  .sidemenu-item:last-of-type {
    margin-bottom: 0;
  }
  .sidemenu-item:hover {
    color: #5995da;
    background: #f5faff;
  }
  .sidemenu-item {
    box-shadow: lightgrey 0px 5px 5px 0px;
    transition-duration: 0.5s;
    text-align: center;
    width: 43px;
    height: 43px;
    border-radius: 100px;
    color: lightgrey;
    cursor: pointer;
    padding: 8px;
    margin-bottom: 20px;
    .sidemenu-item-icon {
      transform: translateY(-5px);
      font-size: 25px;
    }
  }
}
.sidemenu-mobile {
  z-index: 100;
  position: fixed;
  right: 20px;
  bottom: 120px;
  @media only screen and (min-width: 750px) {
    display: none;
  }
  .sidemenu-mobile-wrapper {
    transition: 0.5s;
    display: none;
    opacity: 0;
  }
  .sidemenu-mobile-expand {
    opacity: 0.9;
    z-index: 100;
    background: white;
    position: fixed;
    right: 20px;
    bottom: 40px;
    box-shadow: lightgrey 0px 5px 5px 0px;
    transition-duration: 0.5s;
    text-align: center;
    width: 34px;
    height: 34px;
    border-radius: 100px;
    color: lightgrey;
    cursor: pointer;
    padding: 8px;
    margin-bottom: 20px;
  }
  .sidemenu-mobile-expand-icon {
    transform: translateY(-5px) translateX(0px);
    font-size: 18px;
  }
  .sidemenu-mobile-expand:hover {
    color: #5995da;
    background: #f5faff;
  }
  .sidemenu-item-mobile:last-of-type {
    margin-bottom: 0;
  }
  .sidemenu-item-mobile:hover {
    color: #5995da;
    background: #f5faff;
  }
  .sidemenu-item-mobile {
    opacity: 0.8;
    background: white;
    box-shadow: lightgrey 0px 5px 5px 0px;
    transition-duration: 0.5s;
    text-align: center;
    width: 34px;
    height: 34px;
    border-radius: 100px;
    color: lightgrey;
    cursor: pointer;
    padding: 8px;
    margin-bottom: 10px;
    .sidemenu-item-icon-mobile {
      transform: translateY(-5px);
      font-size: 18px;
    }
  }
}
