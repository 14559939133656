.mission-item-wrapper{
  border-radius: 20px;
  @media only screen and (min-width: 600px) and (max-width: 1200px) {
    padding: 15px 20px;
  }
  @media only screen and (max-width: 599px){
    padding:  10px 15px;
  }
  padding: 20px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 2px 10px 0 rgba(46, 61, 72, 0.05);
  .mission-title-wrapper{
    .mission-title{
      font-size: 16px;
      color: #404040;
      font-weight: bold;
    }
    .mission-description{
      font-size: 14px;
      color: darkgrey;
    }
  }
  .mission-button{
    flex-shrink: 0;
    margin-left: 10px;
    padding: 4px 12px;
    background: #5995DA;
    border-radius: 10px;
    text-align: center;
    border: #5995DA solid 1px;
    color: white;
    cursor: pointer;
    transition: 0.3s;
    &:hover{
      color: #5995DA;
      background: white;
    }
  }
}
