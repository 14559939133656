.interactive-points-reminder {
  position: absolute;
  right: 16px;
  color: darkgrey;
  cursor: pointer;
}
.interactive-points-reminder:hover {
  color: gray;
  svg {
    color: gray;
  }
}

.interactive-points-reminder svg {
  color: darkgrey;
}
