.modal-wrapper{
  position: fixed;
  top:0;
  left: 0;
  height: 100vh;
  display: flex;
  justify-content: center;
  width: 100vw;
  z-index: 10000;
  .modal-layer{
    background: #000;
    opacity: 0.2;
    position:fixed;
    top:0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1001;
  }
  .modal-card{
    max-width: 100vw;
    box-shadow:0 0 10px lightgrey;
    font-size: 12px;
    align-self: center;
    z-index: 10002;
    background: white;
    position: fixed;
    animation: modal1 0.2s linear;
    @keyframes modal1 {
      0%{
        transform: scale(0);
      }
      100%{
        transform: scale(1);
      }
    }
    .close-button-top{
      cursor: pointer;
      color: gray;
      font-size: 20px;
      position: absolute;
      top:-15px;
      right: -10px;
      z-index: 10003;
    }
    .close-button-bottom{
      font-size: 20px;
      cursor: pointer;
      color: white;
      position: absolute;
      z-index: 10003;
      bottom: -40px;
    }
    .modal-title{
      font-weight: 500;
      line-height: 24px;
      font-size: 20px;
      border-bottom: 1px solid rgba(0,0,0,.06);
      padding: 16px 20px 16px 20px;
    }
    .modal-body{
      padding: 10px;
      border-bottom: 1px solid rgba(0,0,0,.06);
    }
    .modal-button{
      padding: 10px;
      float: right;
      position: relative;
      font-size: 14px;
      .ok-button{
        border-radius: 10px;
        cursor: pointer;
        background:#5995DA;
        border: solid #5995DA 1px;
        color: white;
        display: inline-block;
        padding: 6px 20px 6px 20px;
        transition-duration: 0.3s;
      }
      .ok-button:hover{
        background: #78b1f3;
        border: solid #78b1f3 1px;
      }
      .cancel-button:hover{
        background: white;
        border: solid #5995DA 1px;
        color: #5995DA;
      }
      .cancel-button{
        border-radius: 10px;
        transition-duration: 0.3s;
        cursor: pointer;
        background: white;
        border: solid rgba(0,0,0,.1) 1px;
        color: #000000d9;
        margin-right: 10px;
        display: inline-block;
        padding: 6px 20px 6px 20px;
      }
    }
  }
}
