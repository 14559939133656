.coupon-card-wrapper{
  padding:10px;
  display: flex;
  flex-wrap: wrap;
  .coupon-card-item{
    cursor: pointer;
    position: relative;
    .checkbox-selected{
      position: absolute;
      width: 35px;
      height: 35px;
      right: 0;
      bottom: 0;
      &::before{
        content: "";
        display: inline-block;
        border-bottom-right-radius: 10px;
        border-left: 35px solid transparent; /* 左边透明，右边20px宽，可以根据需要调整大小 */
        border-bottom: 35px solid #5995DA;
      }
      .checkbox-selected-icon{
        color: white;
        position: absolute;
        right: 2px;
        bottom: 0;
      }
    }
    &-checkbox{
      @extend .coupon-card-item;
      width: 300px;
      border-radius: 10px;
      padding: 10px 16px;
      margin: 0 20px 20px 0;
    }
    @media only screen and (max-width: 599px){
      width: 100%;
      border-radius: 10px;
      padding: 12px 20px;
      margin: 0 0 10px 0;
      height: 120px;
    }
    display: flex;
    flex-flow: column;
    width: 420px;
    border-radius: 20px;
    padding: 12px 20px;
    box-shadow: 0 2px 6px lightgrey;
    margin: 0 20px 20px 0;
    .item-content-wrapper{
      display: flex;
      justify-content: space-between;
      @media only screen and (max-width: 599px){
        height: 77px;
      }
      height: 90px;
    }
    .item-content{
      display: flex;
      flex-flow: column;
      @media only screen and (max-width: 599px){
        margin-right: 20px;
        width: 200px;
      }
      margin-right: 40px;
      width: 250px;
      .item-title{
        &-checkbox{
          font-size: 14px;
          font-weight: bold;
          margin-bottom: 2px;
        }
        @media only screen and (max-width: 599px){
          font-size: 16px;
          margin-bottom: 4px;
        }
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 4px;
      }
      .item-description{
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        &-checkbox{
          color:#606060;
          font-size: 12px;
          word-break: break-all;
          margin-bottom: 10px;
        }
        @media only screen and (max-width: 599px){
          font-size: 12px;
          margin-bottom: 10px;
        }
        letter-spacing: 1px;
        color:#606060;
        font-size: 14px;
        word-break: break-all;
        margin-bottom: 10px;
      }
    }
    .item-action{
      &-checkbox{
        @extend .item-action;
        width: 100px;
      }
      width: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      .item-discount{
        .not-allowed-notification{
          @media only screen and (max-width: 599px){
            font-size: 14px;
          }
          font-size: 16px;
          text-align: center;
        }
        &-checkbox{
          font-size: 16px;
          font-weight: bold;
          color: #5995DA;
        }
        @media only screen and (max-width: 599px){
          font-size: 16px;
        }
        font-size: 20px;
        font-weight: bold;
        color: #5995DA;
      }
    }
    .item-duration{
      &-checkbox{
        font-size: 12px;
        color: darkgrey;
      }
      @media only screen and (max-width: 599px){
        font-size: 12px;
      }
      font-size: 14px;
      color: darkgrey;
    }
  }
}
