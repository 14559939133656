.recruit{
  overflow-y: hidden;
  padding: 20px;
  display: flex;
  align-items: center;
  flex-flow: column;
  .recruit-carousel{
    padding:  0 20px;
    margin-top: 20px;
    @media only screen and (max-width: 599px){
      width: 100%;
    }
    @media only screen and (min-width: 600px) and (max-width: 1199px){
      max-width: 600px;
      width: 100%;
    }
    @media only screen and (min-width: 1200px){
      max-width: 600px;
    }
    margin-bottom: 20px;
  }
  .recruit-notification{
    font-size: 14px;
    color: #4C62CE;
  }
  .recruit-start-button:hover{
    opacity: 0.95;
  }
  .recruit-start-button{
    cursor: pointer;
    font-weight: bold;
    margin-top: 40px;
    color: white;
    border-radius: 10px;
    font-size: 20px;
    padding: 4px 12px;
    background: #3662EC;
  }
  .recruit-agreement{
    padding: 0 10px;
    font-size: 12px;
    color: dimgrey;
    margin-top: 50px;
  }
}
.recruit-content{
  @media only screen and (max-width: 599px) {
    padding: 10px 10px 0 10px;
  }
  @media only screen and (min-width: 600px) and (max-width: 1199px){
    padding: 20px 20px 0 20px;
  }
  @media only screen and (min-width: 1200px) {
    padding: 30px 30px 0 30px;
  }
  display: flex;
  flex-flow: column;
  .recruit-table{
    .recruit-table-list{
      @media only screen and (max-width: 599px) {
        height: calc(100vh - 330px);
        margin-top: 10px;
      }
      @media only screen and (min-width: 600px) and (max-width: 799px){
        margin-top: 10px;
        height: calc(100vh - 380px);
      }
      @media only screen and (min-width: 800px) and (max-width: 1199px){
        margin-top: 10px;
        height: calc(100vh - 345px);
      }
      @media only screen and (min-width: 1200px) {
        margin-top: 15px;
        height: calc(100vh - 375px);
      }
      overflow-y: scroll;
      .recruit-table-item{
        @media only screen and (max-width: 599px) {
          padding: 10px;
        }
        @media only screen and (min-width: 600px) and (max-width: 1199px){
          padding: 20px;
        }
        @media only screen and (min-width: 1200px) {
          padding: 20px;
        }
        align-items: center;
        border-top:  #e8e8e8 solid 1.5px;
        display: flex;
        justify-content: space-between;
        .post-name{
          @media only screen and (max-width: 599px) {
            font-size: 14px;
            margin-right: 10px;
          }
          @media only screen and (min-width: 600px) and (max-width: 1199px){
            font-size: 16px;
            margin-right: 10px;
          }
          @media only screen and (min-width: 1200px) {
            font-size: 18px;
            margin-bottom: 5px;
          }
          font-weight: bold;
          color: #404040;

        }
        .post-salary{
          color: darkgrey;
          @media only screen and (max-width: 599px) {
            font-size: 12px;
          }
          @media only screen and (min-width: 600px) and (max-width: 1199px){
            font-size: 14px;
          }
          @media only screen and (min-width: 1200px) {
            font-size: 14px;
          }
          font-weight: bold;
        }
        .company-name{
          @media only screen and (max-width: 599px) {
            font-size: 12px;
            margin-right: 10px;
          }
          @media only screen and (min-width: 600px) and (max-width: 1199px){
            font-size: 14px;
            margin-bottom: 6px;
            margin-right: 10px;
          }
          @media only screen and (min-width: 1200px) {
            font-size: 14px;
            margin-bottom: 8px;
          }
        }
        .people-num{
          color: darkgrey;
          @media only screen and (max-width: 599px) {
            font-size: 12px;
          }
          @media only screen and (min-width: 600px) and (max-width: 1199px){
            font-size: 14px;
          }
          @media only screen and (min-width: 1200px) {
            font-size: 14px;
          }
        }
        .submit-resume:hover{
          opacity: 0.95;
        }
        .submit-resume{
          @media only screen and (max-width: 599px) {
            font-size: 12px;
            border-radius: 5px;
            width: 40px;
            padding: 4px 4px 4px 7px;
          }
          @media only screen and (min-width: 600px) and (max-width: 799px){
            font-size: 14px;
            border-radius: 5px;
            width: 50px;
            padding: 4px 4px 4px 10px;
          }
          @media only screen and (min-width: 800px) and (max-width: 1199px){
            padding: 10px 10px;
            font-size: 14px;
            border-radius: 5px;
          }
          @media only screen and (min-width: 1200px) {
            padding: 10px 10px;
            font-size: 14px;
            border-radius: 5px;
          }
          cursor: pointer;
          font-weight: bold;
          color: white;
          background: #1b55f5;
        }
        .star-icon{
          @media only screen and (min-width: 1200px) {
            font-size: 16px;
            margin-left:20px;
          }
          @media only screen and (min-width: 600px) and (max-width: 1199px){
            font-size: 16px;
            margin-left:20px;
          }
          @media only screen and (max-width: 599px) {
            font-size: 14px;
            margin-left: 3vw;
          }

        }
      }
    }
    .recruit-table-search{
      @media only screen and (min-width: 1200px) {

      }
      @media only screen and (min-width: 600px) and (max-width: 799px){
        flex-flow: column;
        align-items: center;
      }
      @media only screen and (max-width: 599px) {
        flex-flow: column;
        align-items: center;
      }
      display: flex;
      align-items: center;
      position: relative;
      padding: 0 10px;
      .recruit-table-la{
        display: flex;
        @media only screen and (max-width: 599px) {
          width: 100%;
          margin-bottom: 10px;
          .recruit-table-search-label:last-of-type{
            margin-right: 0;
          }
        }
        @media only screen and (min-width: 600px) and (max-width: 1199px){
          width: 100%;
          margin-bottom: 10px;
          .recruit-table-search-label:last-of-type{
            margin-right: 0;
          }
        }
        .recruit-table-search-label{
          @media only screen and (min-width: 800px) {
          }
          @media only screen and (min-width: 600px) and (max-width: 799px){
            width: 50%;
          }
          @media only screen and (max-width: 599px) {
            width: 50%;
          }
          font-size: 12px;
          white-space: pre;
          margin-right: 10px;
        }
      }
      .recruit-table-search-input{
        @media only screen and (min-width: 800px) {
          right: 10px;
          position: absolute;
        }
        @media only screen and (min-width: 600px) and (max-width: 799px){
          position: relative;
          width: calc(100vw - 260px);
        }
        @media only screen and (max-width: 599px) {
          position: relative;
          width: calc(100vw - 40px);
        }
        display: flex;
        .search-input{
          border-radius: 5px;
          border: darkgrey solid 1px;
          font-size: 14px;
          @media only screen and (min-width: 800px) {
            width: 200px;
          }
          @media only screen and (min-width: 600px) and (max-width: 799px){
            width: 100%
          }
          @media only screen and (max-width: 599px) {
            width: 100%;
          }
          margin-right: 10px;
          padding: 5px 10px;
        }
        .search-button:hover{
          opacity: 0.95;
        }
        .search-button{
          @media only screen and (min-width: 600px) and (max-width: 1199px){
            min-width: 40px;
            padding: 2px 6px 2px 6px;
          }
          @media only screen and (max-width: 599px) {
            min-width: 40px;
            padding: 2px 6px 2px 6px;
          }
          cursor: pointer;
          color: white;
          font-size: 14px;
          border-radius: 5px;
          padding: 4px 8px;
          background: #1b55f5;
        }
      }
    }
  }
  .recruit-up-arrow{
    display: flex;
    justify-content: center;
    color: lightgrey;
    position: relative;
    @media only screen and (min-width: 1200px) {
      margin-bottom: 4px;
      font-size: 20px;
    }
    @media only screen and (max-width: 599px) {
      margin-bottom: 4px;
      font-size: 16px;
    }
  }
  .recruit-user{
    @media only screen and (max-width: 599px) {
      padding: 10px 10px 0 10px;
    }
    @media only screen and (min-width: 600px) and (max-width: 1199px){
      padding: 20px 30px 0px 30px;
    }
    @media only screen and (min-width: 1200px) {
      padding: 20px 30px 0px 30px;
    }
    box-shadow: 0 2px 10px lightgrey;
    border-radius: 20px;
    width: 100%;
    margin-bottom: 10px;
    .recruit-user-tab{
      display: flex;
      //justify-content: space-around;
      justify-content: space-evenly;
      @media only screen and (min-width: 1200px) {
        padding: 5px 0 10px 0;
      }
      @media only screen and (min-width: 600px) and (max-width: 1199px){
        padding: 5px 0 10px 0;
      }
      @media only screen and (max-width: 599px) {
        padding: 0 0 5px 0;
      }
      .recruit-user-tab-label{
        @media only screen and (max-width: 599px) {
          font-size: 14px;
        }
        @media only screen and (min-width: 600px) and (max-width: 1199px){
          font-size: 16px;
        }
        @media only screen and (min-width: 1200px) {
          font-size: 20px;
        }
        cursor: pointer;
        text-align: center;
        width: 25%;
        padding: 5px 0 0 0;
        color: #505050;
        //border-right: #e8e8e8 solid 1.5px;
      }
      .recruit-user-tab-label:last-of-type{
        border-right:none;
      }
    }
    .recruit-user-info{
      @media only screen and (max-width: 599px) {
        padding-bottom: 10px;
      }
      @media only screen and (min-width: 600px) and (max-width: 1199px){
        padding-bottom: 20px;
      }
      @media only screen and (min-width: 1200px) {
        padding-bottom: 20px;
      }
      display: flex;
      align-items: center;
      flex-flow: column; //
      justify-content: center; //
      border-bottom: #e8e8e8 solid 2px;
      .recruit-user-avatar{
        //@media only screen and (min-width: 1200px) {
        //  margin-right: 30px;
        //}
        //@media only screen and (min-width: 600px) and (max-width: 1199px){
        //  margin-right: 20px;
        //}
        //@media only screen and (max-width: 599px) {
        //  margin-right: 10px;
        //}
      }
      .recruit-user-des{
        color: #808080;
        font-weight: normal;
        @media only screen and (min-width: 1200px) {
          font-size: 16px;
          margin-left: 10px;
        }
        @media only screen and (min-width: 600px) and (max-width: 1199px){
          font-size: 14px;
          margin-left: 8px;
        }
        @media only screen and (max-width: 599px) {
          font-size: 12px;
          margin-left: 4px;
        }
      }
      .recruit-user-post{
        font-weight: bold;
        @media only screen and (min-width: 1200px) {
          font-size: 18px;
          margin-bottom: 10px;
        }
        @media only screen and (min-width: 600px) and (max-width: 1199px){
          font-size: 16px;
          margin-bottom: 5px;
        }
        @media only screen and (max-width: 599px) {
          font-size: 14px;
        }
        color: #3662EC;
      }
      .recruit-user-fe{
        @media only screen and (min-width: 800px) {
          display: flex;
        }
        .recruit-user-fee{
          white-space: pre;
          font-weight: bold;
          @media only screen and (min-width: 1200px) {
            font-size: 18px;
          }
          @media only screen and (min-width: 600px) and (max-width: 1199px){
            font-size: 16px;
            margin-bottom: 5px;
          }
          @media only screen and (max-width: 599px) {
            font-size: 14px;
          }
          color: #3662EC;
        }
        .recruit-user-exp{
          white-space: pre;
          font-weight: bold;
          @media only screen and (min-width: 1200px) {
            font-size: 18px;
            margin-left: 100px;
          }
          @media only screen and (min-width: 600px) and (max-width: 799px){
            font-size: 16px;
          }
          @media only screen and (min-width: 800px) and (max-width: 1199px){
            font-size: 16px;
            margin-left: 50px;
          }
          @media only screen and (max-width: 599px) {
            font-size: 14px;
          }
          color: #3662EC;
        }
      }
    }
  }
}
