._comment-author-tag {
  font-size: 12px;
  color: #5995da;
  background: #f5faff;
  padding: 2px 4px;
  border-radius: 10px;
}

._comment-author-reply {
  @media only screen and (max-width: 599px) {
    padding: 4px 8px;
    font-size: 12px;
  }
  @media only screen and (min-width: 600px) {
    padding: 20px;
    font-size: 14px;
  }
  border-radius: 2px;
  background: #f2f4fa;
  color: #606060;
  margin-bottom: 10px;
}
