.categories{
  position: relative;
  top: 50px;
  overflow-y: scroll;
  @supports (-webkit-appearance: none) {
    height: calc(100dvh - 90px);
  }
  height: calc(100vh - 90px);
  .category-wrapper{
    position: relative;
    border-bottom: solid hsl(0, 0%, 92%) 1px;
    .category-img-wrapper{
      text-align: center;
      .category-img{
        position: relative;
      }
    }
    .category-name-wrapper{
      text-align: center;
      .category-name{
        color: hsl(0,0%,35%);
        position: relative;
        font-family: Verdana;
        font-weight: bold;
      }
    }
    .category-intro-wrapper{
      position: relative;
      padding-top: 20px;
      .purchase-wrapper{
        @media only screen and (min-width: 600px) {
          margin-top: 50px;
        }
        @media only screen and (max-width: 599px) {
          margin-top: 20px;
        }
        text-align: center;
        .price{
          @media only screen and (min-width: 1400px){
            left: -100px;
            font-size: 22px;
          }
          @media only screen and (min-width: 600px) and (max-width: 1399px){
            left: -110px;
            font-size: 20px;
          }
          @media only screen and (max-width: 599px){
            font-size: 14px;
            left: -60px;
          }
          position: relative;
          font-family: Verdana;
          font-weight: bold;

          color: hsl(0,0%,35%);
        }
        .purchase-frequency{
          @media only screen and (min-width: 1400px){
            left: -100px;
          }
          @media only screen and (min-width: 600px) and (max-width: 1399px){
            left: -110px;
          }
          @media only screen and (max-width: 599px){
            font-size: 12px;
            left: -60px;
          }
          color: hsl(0,0%,35%);
          position: relative;
        }
        .purchase-button:hover{
          cursor: pointer;
          color: white;
          background: #5995DA;
        }
        .purchase-button{
          @media only screen and (min-width: 600px) and (max-width: 1399px) {
            left: calc(50% - 210px);
            width: 150px;
          }
          @media only  screen  and (min-width: 1400px){
            left: calc(50% - 220px);
            width: 150px;
          }
          @media only screen and (max-width: 599px){
            left: 65px;
            width: 120px;
            letter-spacing: 2px;
            top: -35px;
            font-size: 14px;
            padding: 4px 8px;
          }
          top: -45px;
          text-align: center;
          letter-spacing: 5px;
          padding: 6px 12px 6px 12px;
          position: relative;
          font-size: 16px;
          background: white;
          border: solid #5995DA 1px;
          color: #5995DA;
      }
      }
      .category-intro{
        line-height: 25px;
        font-family: Verdana;
        position: relative;
        color: dimgrey;
      }
    }
  }
  .lists-wrapper{
    padding-bottom: 40px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1399px){
  .categories{
    width: 100%;
    .category-wrapper{
      padding-bottom: 30px;
      padding-top: 50px;
      .category-img-wrapper{
        .category-img{
          width: 300px;
        }
      }
      .category-name-wrapper{
        padding-top: 20px;
        .category-name{
          font-size: 40px;
        }
      }
      .category-intro-wrapper{
        max-width: 550px;
        left: calc(50vw - 275px);
        .category-intro{
          font-size: 14px;
        }
      }
    }
  }
}
@media only screen and (min-width: 1400px) {
  .categories{
    width: 100%;
    .category-wrapper{
      padding-bottom: 40px;
      padding-top: 70px;
      .category-img-wrapper{
        .category-img{
          width: 300px;
        }
      }
      .category-name-wrapper{
        padding-top: 20px;
        .category-name{
          font-size: 40px;
        }
      }
      .category-intro-wrapper{
        max-width: 600px;
        left: calc(50vw - 300px);
        .category-intro{
          font-size: 14px;
        }
      }
    }
  }
}
@media only screen and (max-width: 599px){
  .categories{
    width: 100vw;
    .category-wrapper{
      padding-bottom: 20px;
      padding-top: 30px;
      .category-img-wrapper{
        .category-img{
          width: 180px;
        }
      }
      .category-name-wrapper{
        padding-top: 10px;
        text-align: center;
        .category-name{
          font-size: 25px;
        }
      }
      .category-intro-wrapper{
        width: 100vw;
        .category-intro{
          padding-right: 20px;
          padding-left: 20px;
          line-height: 25px;
          font-size: 14px;
        }
      }
    }
  }
}
.testpaper_collection-in-category{
  .purchase-wrapper{
    @media only screen and (min-width: 600px) {
      margin-top: 50px;
    }
    @media only screen and (max-width: 599px) {
      margin-top: 20px;
    }
    text-align: center;
    .price{
      @media only screen and (min-width: 1400px){
        left: -100px;
        font-size: 22px;
      }
      @media only screen and (min-width: 600px) and (max-width: 1399px){
        left: -110px;
        font-size: 20px;
      }
      @media only screen and (max-width: 599px){
        font-size: 14px;
        left: -60px;
      }
      position: relative;
      font-family: Verdana;
      font-weight: bold;

      color: hsl(0,0%,35%);
    }
    .purchase-frequency{
      @media only screen and (min-width: 1400px){
        left: -100px;
      }
      @media only screen and (min-width: 600px) and (max-width: 1399px){
        left: -110px;
      }
      @media only screen and (max-width: 599px){
        font-size: 12px;
        left: -60px;
      }
      color: hsl(0,0%,35%);
      position: relative;
    }
    .purchase-button:hover{
      cursor: pointer;
      color: white;
      background: #5995DA;
    }
    .purchase-button{
      @media only screen and (min-width: 600px) and (max-width: 1399px) {
        left: 90px;
        width: 150px;
      }
      @media only  screen  and (min-width: 1400px){
        left: 80px;
        width: 150px;
      }
      @media only screen and (max-width: 599px){
        left: 65px;
        width: 120px;
        letter-spacing: 2px;
        top: -35px;
        font-size: 14px;
        padding: 4px 8px;
      }
      top: -45px;
      text-align: center;
      letter-spacing: 5px;
      padding: 6px 12px 6px 12px;
      position: relative;
      font-size: 16px;
      background: white;
      border: solid #5995DA 1px;
      color: #5995DA;
    }
  }
  .testpaper-list-wrapper{
    position: relative;
    margin-top: 20px;
  }
  @media only screen and (max-width: 599px){
    .testpaper-list-wrapper{
      width: 90%;
      left: 5%;
    }
  }
  @media only screen and (min-width: 1400px) {
    .testpaper-list-wrapper{
      width: 600px;
      left: calc(50% - 300px);
    }
  }
  @media only screen and (min-width: 600px) and (max-width: 1399px){
    .testpaper-list-wrapper{
      width: 550px;
      left: calc(50% - 275px);
    }
  }
}
