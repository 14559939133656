.coupons-wrapper{
  @media only screen and (max-width: 599px){
    padding:10px;
  }
  padding:20px;
  .navigation-menu{
    justify-content: normal!important;
    .navigation-menu-item{
      @media only screen and (max-width: 599px){
        font-size: 16px!important;
        margin: 0 10px!important;
      }
      font-size: 18px!important;
      margin: 0 20px!important;
      &-activated{
        @media only screen and (max-width: 599px){
          font-size: 16px!important;
          margin: 0 10px!important;
        }
        font-size: 18px!important;
        margin: 0 20px!important;
      }
    }
  }
  .coupons-title{
    @media only screen and (max-width: 599px){
      padding: 10px;
      font-size: 18px;
    }
    @media only screen and (min-width: 600px){
      padding: 20px;
      font-size: 30px;
    }
    display: flex;
    align-items: center;
    justify-content: space-between;
    .recommend-code{
      @media only screen and (min-width: 600px){
        margin-top: 10px;
        font-size: 16px;
      }
      @media only screen and (max-width: 599px){
        font-size: 14px;
      }
      color: rgb(174, 174, 174);
      display: flex;
      align-items: center;
      justify-content: space-between;
      .code{
        @media only screen and (min-width: 600px){
          margin: 0 0 -16px 10px;
        }
        @media only screen and (max-width: 599px){
          margin: 0 0 -14px 10px;
        }
        display: flex;
      }
    }
  }
}
