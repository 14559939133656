.testpaper-wrapper{
  .modal{
    z-index: 4;
    top: 300px;
    left: calc(50% - 150px) ;
    position: fixed;
    background: white;
    width: 300px;
    height: 200px;
    border:solid #5995DA 1px;
    span:hover{
      color: white;
      background: #5995DA;
    }
    .modal-message{
      font-size: 16px;
      position: relative;
      top: 50px;
      text-align: center;
    }
    .confirm-button{
      position: relative;
      left: 120px;
      top:100px;
      cursor: pointer;
      background: white;
      color: #5995DA;
      padding: 5px 15px;
      font-size: 14px;
      border: solid #5995DA 1px;
    }
  }
  @media only screen and (min-width: 1400px){
    max-width: 1100px;
    left: calc(50% - 550px);
    padding-left: 40px;
  }

  @media only screen and (max-width : 1399px) and (min-width: 600px) {
    max-width: 600px;
    left: calc(50% - 300px);
    padding-left: 20px;
  }

  @media only screen and (max-width: 599px){
    width: 100%;
    padding-left: 20px;
  }
  position: relative;
  height: auto;
  top: 50px;
  padding-top:40px;
  padding-bottom: 50px;

  .testpaper-title{
    position: relative;
    font-size: 22px;
    color: #5995DA;
    margin-bottom: 40px;
  }
  .questions-wrapper{
    margin-bottom: 100px;
  }

  .purchase-wrapper{
    position: absolute;
    right: 50px;
    @media only screen and (min-width: 1400px){
      top:10px;
    }
    @media only screen and (min-width: 600px) and (max-width: 1399px){
      top: 20px;
    }
    @media only screen and (max-width: 599px){
      top: 20px;
    }
    text-align: center;
    .price{
      @media only screen and (min-width: 1400px){
        font-size: 20px;
      }
      @media only screen and (min-width: 600px) and (max-width: 1399px){
        font-size: 16px;
      }
      @media only screen and (max-width: 599px){
        font-size: 14px;
      }
      position: relative;
      font-family: Verdana;
      font-weight: bold;

      color: hsl(0,0%,35%);
    }
    .purchase-frequency{
      @media only screen and (max-width: 599px){
        font-size: 12px;
      }
      color: hsl(0,0%,35%);
      position: relative;
      margin-bottom: 20px;
    }
    .purchase-button:hover{
      cursor: pointer;
      color: white;
      background: #5995DA;
    }
    .purchase-button{
      @media only screen and (min-width: 600px) and (max-width: 1399px) {
        width: 150px;
        top: -10px;
      }
      @media only  screen  and (min-width: 1400px){
        width: 150px;
        top: -10px;
      }
      @media only screen and (max-width: 599px){
        width: 120px;
        top: -10px;
        letter-spacing: 2px;
        font-size: 14px;
        padding: 4px 8px;
      }
      text-align: center;
      letter-spacing: 5px;
      padding: 6px 12px 6px 12px;
      position: relative;
      font-size: 14px;
      background: white;
      border: solid #5995DA 1px;
      color: #5995DA;
    }
  }
}