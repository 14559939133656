.resume-item-wrapper{
  margin: 5px 10px;
  padding: 5px 0;
  min-height: 140px;
  position: relative;

  .resume-item-title{
    border-left: 4px solid rgb(16, 50, 143);
    padding-left: 6px;
    font-size: 18px;
    font-weight: bold;
  }

  

  .resume-item-content{
    position: relative;
    padding: 10px 6px;
  }
}

.resume-item-wrapper:not(:last-child){
  border-bottom: 1px solid gainsboro;
}