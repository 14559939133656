
.gift-notification{
  right: 10px;
  top:-5px;
  position: fixed;
}
.sidebar-collapse-button{
  cursor: pointer;
  color: darkgrey;
  position: fixed;
  font-size: 30px;
  z-index: 100;
  top:50px;
  @media only screen and (min-width: 600px){
    left: 360px;
  }
  @media only screen and (max-width: 599px) {
    left: calc(100% - 35px);
    font-size: 25px;
  }
  transition-duration: 0.5s;
}
.sidebar-collapse-button:hover{
  color: #5995DA;
}
.sidebar-expand-button{
  cursor: pointer;
  color: darkgrey;
  position: fixed;
  font-size: 30px;
  @media only screen and (max-width: 599px){
    font-size: 25px;
  }
  z-index: 100;
  top:50px;
  left: 10px;
  transition-duration: 0.5s;
}
.sidebar-expand-button:hover{
  color: #5995DA;
}
.chapter{
  .chapter-comments{
    margin-top: 100px;
    position: relative;
    @media only  screen and (min-width: 1200px){
      width: 650px;
      left: calc(50% - 325px);
    }
    @media only screen and (min-width: 600px) and (max-width: 1199px) {
      width: 580px;
      left: calc(50% - 290px);
    }
    @media only screen  and (max-width: 599px){
      width: 100vw;
    }
  }
  overflow-y: scroll;
  overflow-x: hidden;
  scroll-behavior: smooth;
  @supports (-webkit-appearance: none) {
    height: calc(100dvh - 90px);
  }
  height: calc(100vh - 90px);
  transition-duration: 0.5s;
   position: relative;
    .chapter-name-wrapper{
      padding-left: 20px;
      padding-right: 20px;
      text-align: center;
      .hook-position-chapter{
        position: relative;
        height: 0;
        top: -100px;
      }
      .chapter-name{
        font-weight: bold;
        font-family: Verdana;
        color: hsl(0,0%,35%);
      }
    }
    .chapter-content-wrapper{
      position: relative;
      .preview-notification{
        position: relative;
        z-index: 5;
        text-align: center;
        color: #5995DA;
      }
      .lock-info{
        font-size: 14px;
        color: gray;
        text-align: center;
        .lock-img{
          font-weight: lighter;
          margin-left: 10px;
        }
      }
      .chapter-content{
        word-break: break-word;
        font-family: Verdana;
        color: hsl(0,0%,35%);
        line-height: 25px;
      }
      .chapter-content-preview{
        word-break: break-word;
        position: relative;
        font-family: Verdana;
        color: hsl(0,0%,35%);
        line-height: 25px;
        margin-bottom: -20px;
      }
      .chapter-content-preview:before{
        height: 100%;
        content: "";
        position: absolute;
        @media only screen and (max-width: 599px){
          width: calc(100% - 40px);
        }
        @media only screen and (min-width: 600px) {
          width: 100%;
        }
        background: linear-gradient(0deg,white 30px, transparent 90%);
        margin-bottom: -30px;
      }
    }
  }
@media only screen and (min-width: 600px) and (max-width: 1199px){
      .chapter{
        left: 0;
        width: 100%;
       padding: 20px;
        .chapter-name-wrapper{
          .hook-position-chapter{
            height: 0;
            top: -100px;
          }
          .chapter-name{
            font-size: 25px;
          }
        }
        .chapter-content-wrapper{
          max-width: 600px;
          left: 50%;
          transform: translateX(-50%);
          padding-top: 30px;
          .chapter-content{
            font-size: 17px;
          }
          .chapter-content-preview{
            font-size: 17px;
          }
        }
      }
}
@media only screen and (min-width: 1200px){
      .chapter{
        left: 400px;
        width: calc(100% - 400px);
        padding-top: 50px;
        padding-bottom: 50px;
        .chapter-name-wrapper{
          .hook-position-chapter{
            height: 0;
            top: -100px;
          }
          .chapter-name{
            font-size: 30px;
          }
        }
        .chapter-content-wrapper{
          max-width: 680px;
          left: calc(50% - 340px);
          padding-top: 30px;
          .chapter-content{
            font-size: 17px;
          }
          .chapter-content-preview{
            font-size: 17px;
          }
        }
      }
}
@media only screen and (max-width: 599px){
      .chapter{
        padding-top: 20px;
        padding-bottom: 40px;
        .chapter-name-wrapper{
          text-align: center;
          .hook-position-chapter{
            height: 0;
            top: -70px;
          }
          .chapter-name{
            font-size: 16px;
            font-weight: bold;
          }
        }
        .chapter-content-wrapper{
          width: 100vw;
          padding-top: 10px;
          .chapter-content{
            padding-right: 20px;
            padding-left: 20px;
            font-size: 14px;
          }
          .chapter-content-preview{
            padding-right: 20px;
            padding-left: 20px;
            font-size: 14px;
          }
        }
      }
}
