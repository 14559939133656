._comment-item.highlight {
  animation: highlight-animation 0.6s forwards;
  animation-iteration-count: 2;
}
@keyframes highlight-animation {
  0% {
    background-color: transparent;
  }
  50% {
    background-color: #92c9e420;
  }
  100% {
    background-color: transparent;
  }
}
