.user-center-wrapper{
  position: relative;
  top: 50px;
  display: flex;
  justify-content: center;
  .user-center-sidebar{
    padding-top: 20px;
    @media only screen and (min-width: 1200px){
      min-width: 250px;
      font-size: 20px;
    }
    @media only screen and (min-width: 600px) and (max-width: 1199px){
      min-width: 200px;
      font-size: 20px;
    }
    @media only screen and (max-width: 599px){
      display: none;
    }
    height: calc(100vh - 90px);
    border-right: solid 1px #e8e8e8;
    span:hover{
      cursor: pointer;
    }
    .user-center-label{
      font-size: 16px;
      color: gray;
      @media only screen and (min-width: 600px) and (max-width: 1199px){
        margin: 10px 5px 5px 60px;
      }
      @media only screen and (min-width: 1200px){
        margin: 10px 5px 5px 85px;
      }
    }
    .user-center-button{
      justify-content: center;
      display: flex;
      align-items: center;
      .user-center-unread-message-cnt{
        position: absolute;
        @media only screen and (min-width: 1200px){
          left: 175px;
        }
        @media only screen and (min-width: 600px) and (max-width: 1199px){
          left: 150px;
        }
        background: #5995DA;
        padding: 0 3.5px;
        min-width: 15px;
        border-radius: 10px;
        color: #fff;
        font-size: 12px;
        line-height: 15px;
      }
      padding: 8px 20px 8px 20px;
    }
    .user-center-button:hover{
      background-color: rgb(248, 248, 248);
    }
    a{
      color: #606060;
    }
  }
  .user-center-content-wrapper{
    height: calc(100vh - 90px);
    display: flex;
    justify-content: center;
    @media only screen and (max-width: 599px){
      width: 100%;
    }
    @media only screen and (min-width: 600px) and (max-width: 1199px){
      width: calc(100vw - 200px);
    }
    @media only screen and (min-width: 1200px){
      width: calc(100vw - 250px);
    }
    .user-center-content{
      width: 100%;
      z-index: 2;
      overflow: auto;
    }
  }
}