.recruitment-question-all-question-table {
  .ant-table-content {
    overflow-x: scroll;
  }
  .question-row:hover {
    cursor: pointer;
  }
  .question-row.random-selected {
    background-color: #a3c3fa24;
  }
}
